<action-menu>
  <div class="actions-menu-title">
    <b>Minhas Subscrições</b> | <ng-content select=".sub-title"></ng-content>
  </div>

  <div class="actions">
    <a class="nav-link" (click)="clearCart_deep()">Limpar subscrições</a>
    <a class="nav-link" (click)="proceedCheckout_deep()">Agenda</a>
  </div>

  <div class="other-actions"></div>

  <div class="panel-actions">
    <ng-content select=".actions"></ng-content>
  </div>
</action-menu>
