import { Injectable } from '@angular/core';
import { Observable, fromEvent, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ServerSideEvent } from 'app/models/server-side-event';

@Injectable({ providedIn: 'root' })
export class ServerEventService {
  url = environment.eventSourceUrl;
  source = new ReplaySubject<EventSource>(1);
  channels: { [eventType: string]: Subject<ServerSideEvent> } = {};

  constructor() { }

  setupClient(id: string): Observable<EventSource> {
    this.source.next(new EventSource(this.url + 'shop/sub?id=' + id));
    return this.source.asObservable();
  }

  listen(type: string): Observable<ServerSideEvent> {
    if (this.channels[type] === undefined) {
      this.channels[type] = new Subject<any>();
      this.source.pipe(
        switchMap(source => fromEvent(source, type))
      ).subscribe(this.channels[type]);
    }
    return this.channels[type].asObservable();
  }
}
