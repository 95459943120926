import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class NewsletterService {
  url = `${environment.shopUrl}/ContactRS/newsletter`;

  constructor(
    private http: HttpClient
  ) { }

  join(email: string): Observable<any> {
    const params = {
      email,
      source: 'site'
    };

    return this.http.post(this.url, { }, { params });
  }

  cancel(email: string): Observable<any> {
    const params = {
      email,
      source: 'site'
    };

    return this.http.request('delete', this.url, { params });
  }
}
