import { Component, OnInit } from '@angular/core';
import { ClientService } from 'app/services/user/client.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  activeClient = null;

  constructor(
    private clientService: ClientService,
  ) { }

  ngOnInit() {
    this.clientService.getClientChanges()
      .subscribe(client => {
        this.activeClient = client;
      });
  }

}
