import { Injectable } from '@angular/core';

declare var gtag: Function;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  reportNavigation(url: string): void {
    gtag('config', 'G-VGNZ8DY57S', { 'page_path': url });
  }

  reportEvent(name: string, params: any): void {
    gtag('event', name, params);
  }

  reportAdsConversion(transactionId: string): void {
    const params = {
      'send_to': 'AW-360473571/AuexCK_qtOICEOPH8asB',
      'transaction_id': transactionId
    };
    gtag('event', 'conversion', params);
  }
}
