import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageRequestService {
  imagesUrl = environment.imagesUrl;

  width = {
    small: 150,
    medium: 300,
    large: 600
  };

  init(): void {
    /*
    this.width = {
      small: this.calculateWidth(window.devicePixelRatio, 'small'),
      medium: this.calculateWidth(window.devicePixelRatio, 'medium'),
      large: this.calculateWidth(window.devicePixelRatio, 'large')
    };
    */
  }

  private calculateWidth(density: number, tag: string): number {
    const base = 150;
    if (tag === 'medium') {
      return base * 1.5;
    } else if (tag === 'large') {
      return base * 4;
    } else {
      return base;
    }
  }

  getWidth(tag: string): number {
    const width = this.width[tag];
    if (width !== undefined) {
      return this.width[tag];
    }
    return this.width['medium'];
  }
}
