import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-info-page-dynamic',
  templateUrl: './info-page-dynamic.component.html',
  styleUrls: ['../footer-pages-shared.scss'],
  styles: [`
    h1 {
      font-size: 3rem;
    }
  `]
})
export class InfoPageDynamicComponent {
  pages = this.route.paramMap.pipe(
    map(params => params.get('pages') === null ? [] : params.get('pages').split(','))
  );

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {

  }
}
