<div class="modal-header">
  <h4 class="modal-title" id="promoPopupTitle">Promoção Quantidade</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <a routerLink="/browse" [queryParams]="{ s: 'species-dog' }" (click)="activeModal.dismiss()">
    <picture>
      <source srcset="assets/images/quantity-promo-slide.jpg 900w" media="(min-width: 600px)">
      <img src="assets/images/quantity-promo-popup.jpg" alt="Promoção Quantidade" class="img-fluid">
    </picture>
  </a>
</div>
