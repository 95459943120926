import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalizeString' })
export class CapitalizeStringPipe implements PipeTransform {
  constructor() { }

  transform(input: string) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
}
