import { Input, OnInit, Directive, ElementRef } from '@angular/core';
import { FeatureToggleService } from '../services/feature-toggle.service';

@Directive({ selector: '[appDisplayIfFeatureOn]' })
export class DisplayIfFeatureOnDirective implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('appDisplayIfFeatureOn') feature: string;

  constructor(
    private featureToggler: FeatureToggleService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    if (!this.featureToggler.isOn(this.feature)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
