import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { InfoPageDynamicComponent } from './pages/footer-pages/info-page-dynamic/info-page-dynamic.component';
import { AboutUsComponent } from './pages/footer-pages/about-us/about-us.component';
import { DeliveryTermsComponent } from './pages/footer-pages/delivery-terms/delivery-terms.component';
import { GeneralTermsComponent } from './pages/footer-pages/general-terms/general-terms.component';
import { PaymentMethodsComponent } from './pages/footer-pages/payment-methods/payment-methods.component';
import { PrivacyPolicyComponent } from './pages/footer-pages/privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './pages/footer-pages/return-policy/return-policy.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PromotionTermsComponent } from './pages/promotion-terms.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { FaqsComponent } from './pages/footer-pages/faqs/faqs.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { title: 'genialvet - Todos os produtos do seu veterinário, online e ao melhor preço', titleSuffix: '' }
  },
  {
    path: 'subscription',
    component: SubscriptionComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: { title: 'Sobre Nós' }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { title: 'Política de Privacidade' }
  },
  {
    path: 'general-terms',
    component: GeneralTermsComponent,
    data: { title: 'Condições Gerais' }
  },
  {
    path: 'delivery-terms',
    component: DeliveryTermsComponent,
    data: { title: 'Condições de Envio' }
  },
  {
    path: 'payment-methods',
    component: PaymentMethodsComponent,
    data: { title: 'Métodos de Pagamento' }
  },
  {
    path: 'promotion-terms',
    component: PromotionTermsComponent
  },
  {
    path: 'return-policy',
    component: ReturnPolicyComponent,
    data: { title: 'Política de Devolução' }
  },
  {
    path: 'faqs',
    component: FaqsComponent,
    data: { title: 'Perguntas frequentes' }
  },
  {
    path: 'info/:pages',
    component: InfoPageDynamicComponent
  },
  {
    path: 'cart',
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: 'new-pet',
    loadChildren: () => import('./pet-creator/pet-creator.module').then(m => m.PetCreatorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'browse',
    loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile/user',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'favorites',
    loadChildren: () => import('./favorites/favorites.module').then(m => m.FavoritesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile/pets',
    loadChildren: () => import('./animal-profile/animal-profile.module').then(m => m.AnimalProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'profile/clinic',
    loadChildren: () => import('./my-vet/my-vet.module').then(m => m.MyVetModule)
  },
  {
    path: 'clinic-registration',
    loadChildren: () => import('./clinic-registration/clinic-registration.module').then(m => m.ClinicRegistrationModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./brands/brands.module').then(m => m.BrandsModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'browse/pet',
    loadChildren: () => import('./pet-dashboard/pet-dashboard.module').then(m => m.PetDashboardModule)
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'Página não encontrada' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
