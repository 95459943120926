<hr class="brands-divider-top">
<h2 *ngIf="clinicName !== ''" class="text-center">Marcas comercializadas pela clínica {{clinicName}}</h2>
<h2 *ngIf="clinicName === ''" class="text-center">Marcas do seu veterinário, na Genialvet</h2>

<div class="swiper-container pr-5 pl-5">
  <swiper [config]="swiperConfig" [(index)]="index">
    <ng-template swiperSlide *ngFor="let m of manufacturers">
      <a routerLink="/browse/{{m.name | slugify}}" [queryParams]="{ manufacturer: m.id }">
        <img [appStoreImage]="m.logo" size="small" class="brand-logo border" width="90" height="90">
      </a>
    </ng-template>
  </swiper>
</div>

<hr class="brands-divider-bottom">
