<div
  class="b-line middle-card row no-gutters firstBlock"
>
  <div class="col-12 col-lg-8 d-flex flex-column">
    <a *ngIf="products.primary" class="row no-gutters primary-product" routerLink="{{products.primary | productUrl | async}}">
      <div class="col-8">
        <h5 class="text-left pName">{{('product-categories.' + category) | translate}}</h5>
        <p class="text-muted pNameDesc show-more-text">{{productTitles.primary | async}}</p>
        <p class="pPrice">{{(products.primary.price || 0) | cost:'EUR'}}</p>
      </div>
      <div class="col-4">
        <img [appStoreImage]="products.primary.img" size="small" class="primary-product-image">
      </div>
    </a>
  </div>

  <div class="col-12 col-lg-4 d-flex flex-column">
    <a
      *ngIf="products.extraOne"
      routerLink="{{products.extraOne | productUrl | async}}"
      class="thirdCol product-extra"
    >
      <div class="p-1 row r-col-item no-gutters">
        <div class="col-8">
          <div class="p-2 show-more-text h-100">
            <span class="sectionRight_name">
              <b>{{productTitles.extraOne | async}}</b>
            </span>
            <br>
            <span class="text-success sectionRight_price">{{(products.extraOne.price || 0) | cost:'EUR'}}</span>
          </div>
        </div>
        <div class="col-4">
          <img [appStoreImage]="products.extraOne.img" size="small" class="extra-product-image">
        </div>
      </div>
    </a>

    <a
      *ngIf="products.extraTwo"
      routerLink="{{products.extraTwo | productUrl | async}}"
      class="thirdCol product-extra product-extra-two"
    >
      <div class="p-1 row r-col-item no-gutters">
        <div class="col-8">
          <div class="p-2 show-more-text h-100">
            <span class="sectionRight_name">
              <b>{{productTitles.extraTwo | async}}</b>
            </span>
            <br>
            <span class="text-success sectionRight_price">{{(products.extraTwo.price || 0) | cost:'EUR'}}</span>
          </div>
        </div>
        <div class="col-4">
          <img [appStoreImage]="products.extraTwo.img" size="small" class="extra-product-image">
        </div>
      </div>
    </a>
  </div>
</div>
