import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'environments/environment';

import { AnimalService } from 'app/services/animal/animal.service';

import { Product } from 'app/models/product';
import { PriceService } from 'app/services/price/price.service';
import { Pet } from 'app/models/pet';

import { categoryFiltersMap } from 'app/category-filters-map';

@Component({
  selector: 'app-products-grids',
  templateUrl: './products-grids.component.html',
  styleUrls: ['./products-grids.component.scss']
})
export class ProductsGridsComponent implements OnInit, OnChanges {
  @Input() prods: Product[];
  @Input() lastProds: Product[];
  @Input() template = 0;

  imagesUrl = environment.baseUrlServices;
  marcas = ['food', 'hygiene', 'health'];
  activePet: Pet = null;
  dentalbPromoId = categoryFiltersMap['dentalb-promo-product'];

  constructor(
    private activePetService: AnimalService,
    private priceService: PriceService
  ) { }

  ngOnInit() {
    this.activePetService.getSelection().subscribe(data => {
      this.activePet = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['prods'] || changes['lastProds']) {
      const newProds = changes['prods'].currentValue || [];
      const newLastProds = changes['lastProds'].currentValue || [];
      newProds.forEach((p, i) => this.priceService.getActivePrice(p.id)
        .subscribe(price => this.prods[i].price = price.price));
      newLastProds.forEach((p, i) => this.priceService.getActivePrice(p.id)
        .subscribe(price => this.lastProds[i].price = price.price));
    }
  }

  paintBlocks(i: number) {
    switch (i) {
      case 0: return 'firstBlockTemp';
      case 1: return 'middleBlock';
      case 2: return 'lastBlockTemp';
      default: return '';
    }
  }
}
