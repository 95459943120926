import { Injectable } from '@angular/core';

import { ProductsService } from '../product/products.service';

import { Product } from 'app/models/product';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root'
})
export class ProductSearchService {
  constructor(
    private productService: ProductsService,
    private utils: UtilsService
  ) { }

  search(productList: Product[], query: string, language: string): Product[] {
    return productList.filter(product => {
      const normalizedQuery = this.utils.normalizeString(query.toLocaleLowerCase());
      const keywords = normalizedQuery.split(' ');

      return this.nameIncludesQuery(product, keywords, language)
        || this.descIncludesQuery(product, keywords, language)
        || this.featuresIncludeQuery(product, normalizedQuery, language);
    });
  }

  private normalizeName(name: string) {
    return this.utils.normalizeString(name.toLocaleLowerCase().replace('\'', ''));
  }

  private nameIncludesQuery(product: Product, keywords: string[], language: string) {
    const descObj = this.productService.getTranslatedDescription(product, language);
    const name = descObj && descObj.name;
    if (name) {
      return keywords.every(word => this.normalizeName(name).includes(word));
    } else if (product.name) {
      return keywords.every(word => this.normalizeName(product.name).includes(word));
    } else {
      return false;
    }
  }

  private descIncludesQuery(product: Product, keywords: string[], language: string) {
    const descObj = this.productService.getTranslatedDescription(product, language);
    const descText = descObj ? descObj.description.toLocaleLowerCase() : '';
    if (descText) {
      return keywords.every(word => this.utils.normalizeString(descText).includes(word));
    } else {
      return false;
    }
  }

  private featuresIncludeQuery(product: Product, query: string, language: string) {
    const featureTranslations = this.productService.getTranslatedFeatures(product, language);
    return featureTranslations
      .some(translation => translation.toLocaleLowerCase().includes(query));
  }
}
