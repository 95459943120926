import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/header/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { UserComponent } from './components/header/user/user.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LanguageService } from './services/language/language.service';
import { RelatedProductsComponent } from './components/related-products/related-products.component';
import { ClientService } from 'app/services/user/client.service';
import { TokenService } from './services/user/token.service';
import { PetSelectionComponent } from './components/pet-selection/pet-selection.component';
import { CartComponent } from './components/header/cart/cart.component';
import { CartService } from './services/cart/cart.service';
import { SearchBarComponent } from './components/header/search-bar/search-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { TopLineComponent } from './components/header/top-line/top-line.component';
import { SearchLineComponent } from './components/header/search-line/search-line.component';
import { PetComponent } from './components/header/pet/pet.component';
import { FavoriteComponent } from './components/header/favorite/favorite.component';
import { ShippingComponent } from './components/shipping/shipping.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeComponent } from './pages/home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { TopAdsComponent } from './pages/home/top-ads/top-ads.component';
import { SlideshowComponent } from './pages/home/slideshow/slideshow.component';
import { ProductsLinesComponent } from './pages/home/products-lines/products-lines.component';
import { BottomCardsComponent } from './pages/home/bottom-cards/bottom-cards.component';
import { BrandsSlideComponent } from './pages/home/brands-slide/brands-slide.component';
import { BottomCommentsComponent } from './pages/home/bottom-comments/bottom-comments.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ShowMoreTextAreaComponent } from './components/show-more-text-area/show-more-text-area.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoadingComponent } from './components/loading/loading.component';
import { UserNotificationComponent } from './components/user-notification/user-notification.component';
import { GridFiltersComponent } from './pages/productgrid/grid-filters/grid-filters.component';
import { CheckoutMenuComponent } from './pages/checkout/parts/checkout-menu/checkout-menu.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { SubscriptionMenuComponent } from './pages/subscription/parts/subscription-menu/subscription-menu.component';
import { SubscribeComponent } from './components/header/subscribe/subscribe.component';
import { SubscriptionService } from './services/subscription/subscription.service';
import { AnimalService } from 'app/services/animal/animal.service';
import { NomeClinicaComponent } from './components/header/nome-clinica/nome-clinica.component';
import { CurrencyPipe, PercentPipe, DatePipe, registerLocaleData } from '@angular/common';
import { GeneralTermsComponent } from './pages/footer-pages/general-terms/general-terms.component';
import { ReturnPolicyComponent } from './pages/footer-pages/return-policy/return-policy.component';
import { DeliveryTermsComponent } from './pages/footer-pages/delivery-terms/delivery-terms.component';
import { PaymentMethodsComponent } from './pages/footer-pages/payment-methods/payment-methods.component';
import { MarkdownModule } from 'ngx-markdown';
import { InfoPageComponent } from './pages/info-page/info-page.component';
import { SearchPreviewComponent } from './components/search-preview/search-preview.component';
import { DarkenOverlayComponent } from './components/darken-overlay/darken-overlay.component';
import { AuthService } from '@auth0/auth0-angular';
import { AboutUsComponent } from './pages/footer-pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/footer-pages/privacy-policy/privacy-policy.component';
import { CapitalizeStringPipe } from './capitalize-string.pipe';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BrowserModule } from '@angular/platform-browser';
import { ShopBenefitBlockComponent } from './components/shop-benefit-block/shop-benefit-block.component';
import { PromotionTermsComponent } from './pages/promotion-terms.component';
import { AppRoutingModule } from './app-routing.module';
import localePt from '@angular/common/locales/pt-PT';
import localeEs from '@angular/common/locales/es';
import localeGb from '@angular/common/locales/en-GB';
import { CostPipe } from './cost.pipe';
import { InfoPageDynamicComponent } from './pages/footer-pages/info-page-dynamic/info-page-dynamic.component';
import { FaqsComponent } from './pages/footer-pages/faqs/faqs.component';
import { BlogFeedComponent } from './components/blog-feed/blog-feed.component';

registerLocaleData(localePt, 'pt');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeGb, 'gb');

export function tokenGetter() {
  return localStorage.getItem('id_token');
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    AboutUsComponent,
    UserComponent,
    LanguageSelectorComponent,
    RelatedProductsComponent,
    PetSelectionComponent,
    CartComponent,
    SearchBarComponent,
    HeaderComponent,
    TopLineComponent,
    SearchLineComponent,
    PetComponent,
    FavoriteComponent,
    ShippingComponent,
    HomeComponent,
    SlideshowComponent,
    TopAdsComponent,
    ProductsLinesComponent,
    BottomCardsComponent,
    BrandsSlideComponent,
    BottomCommentsComponent,
    ShowMoreTextAreaComponent,
    LoadingComponent,
    UserNotificationComponent,
    GridFiltersComponent,
    CheckoutMenuComponent,
    SubscriptionComponent,
    SubscriptionMenuComponent,
    SubscribeComponent,
    NomeClinicaComponent,
    PrivacyPolicyComponent,
    GeneralTermsComponent,
    ReturnPolicyComponent,
    DeliveryTermsComponent,
    PaymentMethodsComponent,
    InfoPageComponent,
    SearchPreviewComponent,
    DarkenOverlayComponent,
    NotFoundComponent,
    ShopBenefitBlockComponent,
    PromotionTermsComponent,
    InfoPageDynamicComponent,
    FaqsComponent,
    BlogFeedComponent
  ],
  exports: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    AboutUsComponent,
    UserComponent,
    LanguageSelectorComponent,
    RelatedProductsComponent,
    PetSelectionComponent,
    CartComponent,
    SearchBarComponent,
    HeaderComponent,
    TopLineComponent,
    SearchLineComponent,
    PetComponent,
    FavoriteComponent,
    ShippingComponent,
    HomeComponent,
    SlideshowComponent,
    TopAdsComponent,
    ProductsLinesComponent,
    BottomCardsComponent,
    BrandsSlideComponent,
    BottomCommentsComponent,
    ShowMoreTextAreaComponent,
    LoadingComponent,
    UserNotificationComponent,
    GridFiltersComponent,
    CheckoutMenuComponent,
    SubscriptionComponent,
    SubscriptionMenuComponent,
    SubscribeComponent,
    NomeClinicaComponent,
    PrivacyPolicyComponent,
    GeneralTermsComponent,
    ReturnPolicyComponent,
    DeliveryTermsComponent,
    PaymentMethodsComponent,
    InfoPageComponent,
    SearchPreviewComponent,
    DarkenOverlayComponent,
    CapitalizeStringPipe,
    NotFoundComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxPaginationModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    AppRoutingModule,
    ScrollToModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot({ loader: HttpClient }),
    SharedModule
  ],
  providers: [
    ClientService,
    LanguageService,
    TokenService,
    CartService,
    SubscriptionService,
    AnimalService,
    CurrencyPipe,
    PercentPipe,
    CostPipe,
    CapitalizeStringPipe,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
