import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from 'environments/environment';
import {Rest} from 'rest-lib';
import {Manufacturer} from 'app/models/manufacturer';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class  ManufacturerService extends Rest<Manufacturer> {
    private manufacturerUrl = environment.adminUrl + '/manufacturer';  // URL to web api
    private manufacturersUrl = environment.adminUrl + '/manufacturer/list';  // URL to web api
    constructor(http: HttpClient) {
      super(http);
    }

    getUrl(): string {
        return this.manufacturerUrl;
    }
    getUrls(): string {
        return this.manufacturersUrl;
    }

    getByStockCenter(stockCenterId: string): Observable<Manufacturer[]> {
      // TODO: actual endpoint
      return from(this.getAll());
    }



}
