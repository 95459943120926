import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'app/services/language/language.service';

import { Lang } from '../../models/lang';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  languagesList: Lang[];
  selectedLanguage: Lang;
  public active = 'pt';

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    const langPt = new Lang();
    langPt.language = 'Português';
    langPt.code = 'pt';

    const langEs = new Lang();
    langEs.language = 'Castelhano';
    langEs.code = 'es';

    const langEn = new Lang();
    langEn.language = 'Inglês';
    langEn.code = 'gb';

    this.languagesList = new Array();
    this.languagesList.push(langPt);
    this.languagesList.push(langEs);
    this.languagesList.push(langEn);
    this.selectedLanguage = langPt;
  }

  select(f: Lang, event) {
    this.translate.use(f.code).subscribe(result => {
      this.languageService.currentLanguage.next(f.code);
    });
    this.selectedLanguage = f;
  }
}

