import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, merge } from 'rxjs';
import { mergeMap, catchError, map, scan } from 'rxjs/operators';
import { Rest } from 'rest-lib';
import { environment } from 'environments/environment';

import { ShippingDestinationService } from '../shipping-destination.service';

import { Price } from 'app/models/price';

@Injectable({
  providedIn: 'root'
})
export class PriceService extends Rest<Price> {
  constructor(
    http: HttpClient,
    private shippingService: ShippingDestinationService
  ) {
    super(http);
  }

  getUrl() {
    return `${environment.baseUrl}`;
  }

  getUrls() {
    return `${environment.baseUrl}`;
  }

  getPrice(productId: string, country: string): Observable<Price> {
    const url = `${this.getUrl()}/public/product/${productId}/price/${country}`;

    // return of(mockPrice);
    return this.http.get<Price>(url);
  }

  getMultipleItemsPrice(productId: string, country: string, itemAmount: number) {
    const url = `${this.getUrl()}/public/product/${productId}/multiples-price/${country}`;
    return this.http.get<Price>(url, { params: { numberItens: String(itemAmount) } });
  }

  getActivePrice(productId: string): Observable<Price> {
    return this.shippingService.getDestination().pipe(
      mergeMap(dest => this.getPrice(productId, dest.code).pipe(
        catchError(() => of({ productId, price: 0 } as Price))
      ))
    );
  }

  getActiveMultiplePrice(productId: string, amount: number): Observable<Price> {
    return this.shippingService.getDestination().pipe(
      mergeMap(dest => this.getMultipleItemsPrice(productId, dest.code, amount).pipe(
        catchError(() => of({ productId, price: 0 } as Price))
      ))
    );
  }

  getActivePrices(productIds: string[]): Observable<{ [id: string]: Price }> {
    const makeRequest = (id: string) => this.getActivePrice(id)
      .pipe(map(price => ({ [id]: price })));
    return merge(...productIds.map(id => makeRequest(id)))
      .pipe(scan((m, p) => ({ ...m, ...p })));
  }
}
