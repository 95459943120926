import { Component, OnInit } from '@angular/core';

import { ClientService } from 'app/services/user/client.service';
import { AnimalService } from 'app/services/animal/animal.service';

@Component({
  selector: 'app-nome-clinica',
  templateUrl: './nome-clinica.component.html',
  styleUrls: ['./nome-clinica.component.scss']
})
export class NomeClinicaComponent implements OnInit {
  petClinic = '';
  userClinic = '';

  constructor(
    private clientService: ClientService,
    private petService: AnimalService
  ) { }

  ngOnInit() {
    this.petService.getSelection().subscribe(pet => {
      if (pet) {
        this.petClinic = pet.clinicName;
      } else {
        this.petClinic = '';
      }
    });
    this.clientService.getClient().subscribe(client => {
      if (client && client.clinicName) {
        this.userClinic = client.clinicName;
      } else {
        this.userClinic = '';
      }
    });
  }

}
