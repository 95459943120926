
<div id="search-line" class="row col-12" style="margin-top: 0.5em; padding-right: 0em; padding-left: 0em; padding-top: 0.5em;">
  <div class="col-12 col-xl-7 p-0">
    <form class="form-inline my-2 my-lg-0">
      <app-search-bar></app-search-bar>
    </form>
  </div>
  <div class="personal-area col-12 col-xl-5 p-0">
    <div class="align-self-center mouseHover" style="margin-right:0.5em;">
      <app-pet></app-pet>
    </div>
    <div class="align-self-center mouseHover" style="margin-right:0.5em;">
      <app-user></app-user>
    </div>
    <div [appDisplayIfFeatureOn]="'subscription'" class="align-self-center" style="margin-right:0.5em;">
      <app-subscribe></app-subscribe>
    </div>
    <div class="align-self-center" style="margin-right:0.5em;">
      <app-favorite></app-favorite>
    </div>
    <div class="align-self-center" style="padding-right: 0">
      <app-cart></app-cart>
    </div>
  </div>
