import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class SourceInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.activeHosts.some(host => req.url.includes(host))) {
      const sourceRequest = req.clone({
        headers: req.headers.append('Client-Source', 'WEB:Client')
      });

      return next.handle(sourceRequest);
    } else {
      return next.handle(req);
    }
  }
}
