import { Pipe, PipeTransform } from '@angular/core';
import { map, tap, startWith } from 'rxjs/operators';
import { LanguageService } from './services/language/language.service';

@Pipe({ name: 'objTranslate' })
export class ObjectTranslatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) { }

  transform(input: any[]) {
    const translationsObs = this.languageService.currentLanguage.pipe(
      map(language => {
        const translation = input.find(tl => tl.language === language);
        return translation ? translation : input.find(tl => tl.language !== undefined);
      })
    );
    return translationsObs;
  }
}
