import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, throwError, of } from 'rxjs';
import { environment } from 'environments/environment';

import { Clinic } from 'app/models/clinic';
import { ClientService } from './user/client.service';
import { AnimalService } from './animal/animal.service';
import { take, map, first, switchMap } from 'rxjs/operators';
import { Client } from 'app/models/client';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  baseUrl = `${environment.adminUrl}/clinic`;

  constructor(
    private http: HttpClient,
    private clientService: ClientService,
    private petService: AnimalService,
    private utils: UtilsService
  ) { }

  get(id: string): Observable<Clinic> {
    return this.http.get<Clinic>(`${this.baseUrl}/${id}`);
  }

  getAll(): Observable<Clinic[]> {
    return this.http.get<Clinic[]>(`${this.baseUrl}/list`);
  }

  isAffiliate(clinic: Clinic) {
    return clinic.partnerId !== undefined;
  }

  getActiveClinic(): Observable<string | null> {
    const clientClinic = this.clientService.getClientChanges().pipe(
      take(1),
      map(client => client ? client.clinicId : null)
    );
    const petClinic = this.petService.getSelection().pipe(
      take(1),
      map(pet => pet ? pet.clinicId : null)
    );
    return forkJoin([petClinic, clientClinic]).pipe(
      map(values => values[0] !== null ? values[0] : values[1] !== null ? values[1] : null)
    );
  }

  getAllAffiliate(): Observable<string[]> {
    const collectAffiliate = (client: Client): string[] => {
      const clientClinic = client && client.clinicId ? client.clinicId : [];
      const petClinics = client && client.pets ? client.pets.map(pet => pet.clinicId) : [];
      return this.utils.unique([].concat(clientClinic, petClinics));
    };

    return this.clientService.getClientChanges().pipe(
      map(client => collectAffiliate(client))
    );
  }
}
