import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  constructor() { }

  transform(text: string, size: number) {
    if (text.length <= size) {
      return text;
    }

    const lastSpace = text.lastIndexOf(' ', size - 1);
    const truncated = text.substring(0, lastSpace) + '...';
    return truncated;
  }
}
