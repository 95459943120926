import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['../footer-pages-shared.scss', './payment-methods.component.scss']
})
export class PaymentMethodsComponent {
  constructor() { }

}
