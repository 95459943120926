import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Product } from 'app/models/product';
import { ProductsService } from 'app/services/product/products.service';

@Component({
  selector: 'app-pet-product-suggestion-row',
  templateUrl: './pet-product-suggestion-row.component.html',
  styleUrls: ['./pet-product-suggestion-row.component.scss']
})
export class PetProductSuggestionRowComponent implements OnInit, OnChanges {
  @Input() category: string = '';
  @Input() products: { primary: Product, extraOne: Product, extraTwo: Product } = {
    primary: null,
    extraOne: null,
    extraTwo: null
  };

  productTitles = {
    primary: null,
    extraOne: null,
    extraTwo: null
  };

  constructor(
    private productService: ProductsService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.products && changes.products.currentValue) {
      const products = changes.products.currentValue;
      this.productTitles = {
        primary: this.productService.getTitle(products.primary),
        extraOne: this.productService.getTitle(products.extraOne),
        extraTwo: this.productService.getTitle(products.extraTwo)
      };
    }
  }

}
