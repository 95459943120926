import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class FeatureToggleService {
  features: any;

  constructor() {
    this.features = environment.features;
  }

  isOn(feature: string): boolean {
    return this.features[feature];
  }
}
