<div class="row header align-items-center">
  <div class="col col-3 info">
    <ng-content select=".actions-menu-title"></ng-content>
  </div>

  <div class="col col-6 actions-container row justify-content-between">
    <ng-content select=".actions"></ng-content>
  </div>

  <div class="col col-3 context-actions">
    <ng-content select=".panel-actions"></ng-content>
  </div>
</div>
