import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Shipping } from 'app/models/shipping';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingDestinationService {
  destination: BehaviorSubject<Shipping>;
  destinations: Shipping[];

  constructor(
    private localStorageService: LocalStorageService
  ) {
    this.destinations = [
      { destination: 'Portugal', code: 'pt', available: true },
      { destination: 'Espanha', code: 'es', available: false },
      { destination: 'Inglaterra', code: 'gb', available: false }
    ];

    const storedCountryCode = this.localStorageService.getItem('destination');
    if (storedCountryCode) {
      const storedDestination = this.destinations.find(item => item.code === storedCountryCode);
      this.destination = new BehaviorSubject<Shipping>(storedDestination);
    } else {
      this.destination = new BehaviorSubject<Shipping>(this.destinations[0]);
    }
  }

  connectDestinationSelector(emitter) {
    emitter.subscribe(value => {
      this.destination.next(value);
      this.localStorageService.setItem('destination', this.destination.value.code);
    });
    return this.destination.value;
  }

  getDestinationList() {
    return this.destinations;
  }

  getDestination(): Observable<Shipping> {
    return this.destination.asObservable();
  }
}
