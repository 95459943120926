import { Injectable } from '@angular/core';
import { Product } from 'app/models/product';
import { concat, forkJoin, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureService } from './feature.service';
import { ProductsService } from './product/products.service';
import { UtilsService } from './utils.service';



@Injectable({
  providedIn: 'root'
})
export class RecommendedProductsService {
  constructor(
    private featureService: FeatureService,
    private utils: UtilsService,
    private productService: ProductsService,
  ) { }

  getByType(productList: Product[], type: string): Observable<Product[]> {
    return this.featureService.getByKey('productType-' + type).pipe(
      map(features => productList.filter(p => p.features.some(f => f.featureId === features[0].id)))
    );
  }

  hasSpecies(product: Product, speciesId: string) {
    return product.features.some(feature => feature.featureId === speciesId);
  }

  forGrid(
    productList: Product[],
    types: string[],
    speciesId?: string
  ): Observable<{ category: string, list: Product[] }> {
    const makeRequest = (type: string) =>
      this.getByType(productList, type).pipe(
        map(list => speciesId !== undefined
          ? list.filter(product => this.hasSpecies(product, speciesId))
          : list),
        map(list => this.utils.takeRandom<Product>(list, 3)),
        map(products => ({ category: type, list: products }))
      );
    const requests = types.map(type => makeRequest(type));
    return concat(...requests);
  }

  getRandomPromoted(species: string | null): Observable<Product[]> {
    return this.getRandomPromotedBySpecies(species);
  }

  private getRandomPromotedBySpecies(species: string | null): Observable<Product[]> {
    const productsRequest = this.productService.getByCountryInStock();
    const requests: Observable<any>[] = [
      productsRequest,
      this.featureService.getByKey('promotionState-promoted'),
    ];
    if (species !== null) {
      requests.push(this.featureService.getByKey('species-' + species));
    }
    return forkJoin(requests).pipe(
      map(res => {
        const products = res[0];
        const promotedFeature = res[1][0];
        const speciesFeature = res[2] !== undefined ? res[2][0] : null;

        if (speciesFeature !== null) {
          return products.filter(p => p.features.some(f => f.featureId === promotedFeature.id)
            && p.features.some(f => f.featureId === speciesFeature.id));
        } else {
          return products.filter(p => p.features.some(f => f.featureId === promotedFeature.id));
        }
      }),
      map(list => this.utils.takeRandom<Product>(list, 9))
    );
  }

  private getRandomPromotedAll(): Observable<Product[]> {
    return forkJoin([
      from(this.productService.getAll()),
      this.featureService.getByKey('promotionState-promoted')
    ]).pipe(
      map(res => {
        const products = res[0];
        const promotedFeature = res[1][0];
        return products.filter(p => p.features.some(f => f.featureId === promotedFeature.id));
      }),
      map(list => this.utils.takeRandom<Product>(list, 9))
    );
  }
}
