import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AnimalModel } from '../../../models/animal-model';
import { AnimalService } from 'app/services/animal/animal.service';
import { Pet } from '../../../models/pet';
import { Client } from '../../../models/client';
import { ClientService } from '../../../services/user/client.service';
import { TokenService } from 'app/services/user/token.service';

import { Observable, pipe } from 'rxjs';
import { filter, map, reduce } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pet',
  templateUrl: './pet.component.html',
  styleUrls: ['./pet.component.scss']
})
export class PetComponent implements OnInit {
  imagesUrl = environment.imagesUrl;
  client: Client;
  selectedPet: Pet = null;
  defaultPetImage = '/assets/images/icons/Registo Animal.svg';
  petImage: string;

  constructor(
    private auth: AuthService,
    private clientService: ClientService,
    private activePetService: AnimalService,
    private router: Router
  ) {
    this.petImage = this.defaultPetImage;
  }

  ngOnInit() {
    this.clientService.getClientChanges()
      .subscribe(client => this.client = client);
    this.activePetService.getSelection().subscribe(pet => {
      this.selectedPet = pet;
      this.setPetImage(pet);
    });
  }

  select(pet: Pet) {
    this.selectedPet = pet;
    this.setPetImage(pet);
    this.activePetService.setSelection(this.selectedPet);
  }

  getPet(sub: any): void {
    if (!sub || sub === null) {
      this.selectedPet = null;
      return;
    }
    this.clientService.getClient().subscribe(r => {
      if (!r) {
        return;
      }
      this.client = r;
      if (this.client.pets && this.client.pets.length > 0) {
        const pet = this.client.pets[0];
        this.select(pet);
        setTimeout(function () { }, 1);
        this.setPetImage(pet);
      }
    });
  }

  private setPetImage(pet: Pet) {
    if (pet && pet.img) {
      this.petImage = this.imagesUrl + '/' + pet.img;
    } else {
      this.petImage = '/assets/images/icons/Registo Animal.svg';
    }
  }

  handleRegisterClick() {
    this.auth.loginWithRedirect();
  }
}
