import { Component } from '@angular/core';

@Component({
  selector: 'app-general-terms',
  templateUrl: './general-terms.component.html',
  styleUrls: ['../footer-pages-shared.scss', './general-terms.component.scss']
})
export class GeneralTermsComponent {
  constructor() { }

}
