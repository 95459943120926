import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CostPipe } from '../cost.pipe';
import { EmptyCollectionNoticeComponent } from 'app/components/empty-collection-notice/empty-collection-notice.component';
import { RecommendedLineComponent } from 'app/components/recommended-line/recommended-line.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ObjectTranslatePipe } from 'app/object-translate.pipe';
import { InputValidatorDisplayComponent } from 'app/components/input-validator-display/input-validator-display.component';
import { ObjectTypeaheadComponent } from 'app/components/object-typeahead/object-typeahead.component';
import { AutofocusFixDirective } from 'app/directives/autofocus-fix.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreImageDirective } from 'app/directives/store-image.directive';
import { RatingComponent } from 'app/components/rating/rating.component';
import { DisplayIfFeatureOnDirective } from 'app/directives/display-if-feature-on.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { SwiperModule } from 'swiper/angular';
import { ActionMenuComponent } from 'app/components/action-menu/action-menu.component';
import { GridSearchComponent } from 'app/pages/productgrid/grid-search/grid-search.component';
import { ProductCardComponent } from 'app/pages/productgrid/product-card/product-card.component';
import { FormSaveLineComponent } from 'app/components/form-save-line/form-save-line.component';
import { LocalizedDatePipe } from 'app/localized-date.pipe';
import { CapitalizeStringPipe } from 'app/capitalize-string.pipe';
import { ProductsGridsComponent } from 'app/components/products-grids/products-grids.component';
import { PetProductSuggestionRowComponent } from 'app/components/pet-product-suggestion-row/pet-product-suggestion-row.component';
import { PromotionNoticeComponent } from 'app/components/promotion-notice/promotion-notice.component';
import { RatingControlComponent } from 'app/components/rating-control/rating-control.component';
import { OrderStateTranslatePipe } from 'app/order-state-translate.pipe';
import { PromoPopupComponent } from 'app/components/promo-popup/promo-popup.component';
import { ProductUrlPipe } from 'app/product-url.pipe';
import { SlugifyPipe } from '../slugify.pipe';
import { JsonLdComponent } from '../components/json-ld/json-ld.component';
import { TruncatePipe } from '../truncate.pipe';


@NgModule({
  declarations: [
    CostPipe,
    ObjectTranslatePipe,
    EmptyCollectionNoticeComponent,
    RecommendedLineComponent,
    InputValidatorDisplayComponent,
    ObjectTypeaheadComponent,
    AutofocusFixDirective,
    StoreImageDirective,
    RatingComponent,
    DisplayIfFeatureOnDirective,
    ActionMenuComponent,
    GridSearchComponent,
    ProductCardComponent,
    FormSaveLineComponent,
    InputValidatorDisplayComponent,
    LocalizedDatePipe,
    CapitalizeStringPipe,
    ProductsGridsComponent,
    PetProductSuggestionRowComponent,
    PromotionNoticeComponent,
    RatingControlComponent,
    OrderStateTranslatePipe,
    PromoPopupComponent,
    ProductUrlPipe,
    SlugifyPipe,
    JsonLdComponent,
    TruncatePipe
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SwiperModule
  ],
  exports: [
    CostPipe,
    ObjectTranslatePipe,
    EmptyCollectionNoticeComponent,
    RecommendedLineComponent,
    InputValidatorDisplayComponent,
    ObjectTypeaheadComponent,
    TranslateModule,
    RouterModule,
    AutofocusFixDirective,
    StoreImageDirective,
    RatingComponent,
    DisplayIfFeatureOnDirective,
    NgxPaginationModule,
    ActionMenuComponent,
    SwiperModule,
    GridSearchComponent,
    ProductCardComponent,
    FormSaveLineComponent,
    LocalizedDatePipe,
    CapitalizeStringPipe,
    ProductsGridsComponent,
    PetProductSuggestionRowComponent,
    PromotionNoticeComponent,
    RatingControlComponent,
    OrderStateTranslatePipe,
    PromoPopupComponent,
    ProductUrlPipe,
    SlugifyPipe,
    JsonLdComponent,
    TruncatePipe
  ]
})
export class SharedModule { }
