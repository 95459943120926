import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';

import { Pet } from '../../models/pet';
import { switchMap, map } from 'rxjs/operators';
import { FeatureService } from '../feature.service';
import { ClientService } from '../user/client.service';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class AnimalService {
  private subject = new BehaviorSubject<Pet>(null);

  constructor(
    private featureService: FeatureService,
    private clientService: ClientService,
    private localStorageService: LocalStorageService
  ) {
    this.clientService.getClientChanges().pipe(
      map(client => {
        if (client && client.pets && client.pets.length > 0) {
          return client.pets[0];
        }
        return null;
      })
    ).subscribe(this.subject);
  }

  setSelection(selected: Pet) {
    this.localStorageService.setItem('selectedPET', JSON.stringify(selected));
    this.subject.next(selected);
  }

  clearSelection() {
    this.subject.next(null);
  }

  getSelection(): BehaviorSubject<Pet> {
    return this.subject;
  }

  getSpeciesName(): Observable<string | undefined> {
    return this.getSelection().pipe(
      switchMap(pet => {
        if (pet !== null) {
          return this.featureService.get(pet.species);
        } else {
          return of(null);
        }
      }),
      map(speciesFeature => {
        if (speciesFeature !== null) {
          return speciesFeature.key.match(/species-(.+)/)[1];
        } else {
          return null;
        }
      })
    );
  }

}
