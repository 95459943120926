import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-subscription-menu',
  templateUrl: './subscription-menu.component.html',
  styleUrls: ['./subscription-menu.component.scss']
})
export class SubscriptionMenuComponent implements OnInit {

  @Output() clearCart = new EventEmitter();
  @Output() proceedCheckout = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  clearCart_deep() {
    this.clearCart.emit();
  }

  proceedCheckout_deep() {
    this.proceedCheckout.emit();
  }
}
