import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { ClientService } from 'app/services/user/client.service';

import { ManufacturerService } from 'app/services/manufacturer.service';
import { from } from 'rxjs';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';

@Component({
  selector: 'app-brands-slide',
  templateUrl: './brands-slide.component.html',
  styleUrls: ['./brands-slide.component.scss']
})
export class BrandsSlideComponent implements OnInit {
  swiperConfig: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: false,
    centeredSlides: false,
    loop: false,
    simulateTouch: true,
    speed: 1000,
    freeModeMomentum: true,
    followFinger: true,
    observer: true,
    preloadImages: true,
    longSwipesMs: 500,
    updateOnImagesReady: true,
    freeModeMinimumVelocity: 0.1,
    autoplay: false,
    navigation: true
  };
  clinicName = '';
  index = 0;
  manufacturers = [];

  constructor(
    private ref: ChangeDetectorRef,
    private clientService: ClientService,
    private manufacturerService: ManufacturerService
  ) {
    SwiperCore.use([Navigation]);
  }

  ngOnInit() {
    this.clientService.getClientChanges().subscribe(client => {
      if (client === null || client && !client.clinicId) {
        this.clinicName = '';
        from(this.manufacturerService.getAll()).subscribe(ms => {
          this.manufacturers = ms;
        });
      } else {
        if (client.clinicName) {
          this.clinicName = client.clinicName;
        }
        if (client.clinicId) {
          this.manufacturerService.getByStockCenter(client.clinicId)
            .subscribe(res => this.manufacturers = res);
        }
      }
    });
    this.ref.detectChanges();
  }
}
