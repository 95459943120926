import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Rest } from 'rest-lib';
import { environment } from 'environments/environment';
import { CartService } from '../cart/cart.service';
import { ClientService } from '../user/client.service';

import { Order } from '../../models/order';
import { BOrder } from 'app/models/backend/b-order';
import { Cart } from 'app/models/backend/cart';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends Rest<any> {
  private orderUrl = environment.shopUrl + '/orders';
  currentOrder: BOrder = null;

  constructor(
    public http: HttpClient,
    private cartService: CartService,
    private clientService: ClientService
  ) {
    super(http);
  }

  getUrls(): string {
    return this.orderUrl;
  }

  getUrl(): string {
    return this.orderUrl;
  }

  getAllOrders(clientId: string): Observable<BOrder[]> {
    const url = `${environment.shopUrl}/orders/client/${clientId}`;
    return this.http.get<BOrder[]>(url);
  }

  getByRef(ref: string) {
    return this.http.get<BOrder>(`${this.orderUrl}/refOrder/${ref}`);
  }

  cartToOrder(cartId: string, clientId: string): Observable<BOrder> {
    const url = `${environment.shopUrl}/cart/${clientId}/${cartId}/toOrder`;
    return this.http.post<BOrder>(url, null);
  }

  updateOrder(orderId: string, data: BOrder): Observable<BOrder> {
    const url = `${this.orderUrl}/${orderId}`;
    return this.http.put<BOrder>(url, data);
  }

  validate(orderId: string) {
    const url = `${this.orderUrl}/${orderId}/validate`;
    return this.http.put(url, {});
  }

  getMinimumPurchaseValue(): number {
    return 2000;
  }

  canResumeOrder(order: BOrder): boolean {
    return order.orderState === 'New' || order.orderState === 'Validated';
  }

  getShippingAmount(countryCode: string, billingTotal: number, zipCode?: string): Observable<any> {
    const url = `${environment.baseUrl}/shippingCosts/${countryCode}`;
    const params = {} as any;
    params.billingTotal = '' + billingTotal;
    if (zipCode) {
      params.zipCode = zipCode;
    }
    return this.http.get<any>(url, { params });
  }

  validateRef(s: string): boolean {
    const regex = /[0-9]{4}-[0-9A-Za-z]{7}/;
    return regex.test(s);
  }

  hasCoupon(order: BOrder) {
    const discounts = order.billingDetail.discounts;
    return discounts !== undefined
      && discounts.some(discount => discount.key === 'coupon');
  }
}
