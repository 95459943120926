<div class="row header" id="vetg_header">
  <div class="col-12 d-xl-none" style="margin-top: 0.5em;">
    <app-top-line class="row clearfix justify-content-center"></app-top-line>
  </div>
  <div class="col-12 col-xl-2 d-flex flex-column justify-content-center">
    <a routerLink="/" class="brand-home-link">
      <img
        class="dark-shadow brand-logo"
        src="/assets/images/Logo_white-01.svg"
        alt="genialvet"
        width="200"
        height="60"
      >
    </a>
    <div *ngIf="client">
      <app-nome-clinica class="clinic"></app-nome-clinica>
    </div>
  </div>

  <div class="row col col-12 col-xl-10">
    <div class="col-12 d-none d-xl-block" style="margin-top: 0.5em;">
      <app-top-line class="row clearfix justify-content-end"></app-top-line>
    </div>
    <app-search-line class="col-12"></app-search-line>
    <!-- <app-menu class="col-12  justify-content-center"></app-menu> -->
  </div>

  <app-menu class="row col col-12 mt-2"></app-menu>
</div>
