import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'environments/environment';

@Injectable()
export class IdTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = new URL(req.url, environment.authRedirect);
    if (environment.authorizedDomains.includes(reqUrl.host)) {
      return this.rawIdToken().pipe(
        switchMap(token => {
          if (token !== null) {
            return next.handle(req.clone({ setHeaders: { 'Authorization': `Bearer ${token}` } }));
          }
          return next.handle(req);
        })
      );
    }
    return next.handle(req);
  }

  private rawIdToken(): Observable<string | null> {
    return this.authService.idTokenClaims$.pipe(
      take(1),
      map(claims => claims !== null ? claims.__raw : null)
    );
  }
}
