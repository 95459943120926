<div *ngIf="heading">
  <hr>
  <h3 style="color:#008675 !important;" class="h4 p-3 font-weight-bold ml-0">
    {{heading}}
  </h3>
</div>

<div *ngIf="products" class="container-fluid row col-12 prodLine pt-4 pb-2 pr-4 pl-4">
  <div class="swiper-container">
    <swiper
      [config]="swiperConfig"
      [(index)]="index"
    >
      <ng-template swiperSlide *ngFor="let recomProd of products">
        <div class="row recommendedProducts">
          <div class="recommendedImage col-12">
            <img
              [appStoreImage]="recomProd.img"
              size="small"
              routerLink="{{recomProd | productUrl | async}}"
              class="img-thumbnail p-2"
              [alt]="(recomProd.descriptions | objTranslate | async).name"
            >
          </div>
          <div class="recommendedText col-12 pl-4">
            <div class="p-2 title">{{productTitlesMap.get(recomProd.id) | async}}</div>
            <div class="p-2 recPrice row">
              <div class="mr-auto">
                <span class="nowPrice">{{prices[recomProd.id]?.price || 0 | cost:'EUR'}}</span>
                &nbsp;&nbsp;
                <span class="oldPrice text-muted">{{prices[recomProd.id]?.phantomPrice || 0 | cost:'EUR'}}</span>
              </div>
            </div>
            <div class="recButton">
              <button
                type="button"
                (click)="addToCart(recomProd)"
                class="btn btn-primary w-100"
                [disabled]="!stocks[recomProd.id]"
              >
                {{actionLabel | translate}}
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
