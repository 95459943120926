import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['../footer-pages-shared.scss', './privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  constructor() { }

}
