import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of, from, Subject } from 'rxjs';
import { tap, map, first, mergeMap, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { ClientService } from '../user/client.service';
import { ProductsService } from '../product/products.service';
import { LanguageService } from '../language/language.service';

import { CartItem } from '../../models/cart-item';
import { Product, ProductTranslated } from 'app/models/product';
import { Cart } from 'app/models/backend/cart';
import { BOrderItem } from 'app/models/backend/b-order-item';
import { PriceService } from '../price/price.service';
import { Price } from 'app/models/price';
import { UserNotificationService } from '../language/user-notification.service';
import { ProductNotification } from 'app/models/product-notification';
import { StockService } from '../stock/stock.service';
import { TranslateService } from '@ngx-translate/core';
import { Description } from 'app/models/description';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Injectable()
export class CartService {
  cart: Cart;
  cartUpdateUrl = '';
  currentCartId = '';
  private subject = new BehaviorSubject<Cart>(null);
  shopUrl = environment.shopUrl;
  cartUpdateSubject = new BehaviorSubject<Cart>(null);

  constructor(
    private clientService: ClientService,
    private productService: ProductsService,
    private languageService: LanguageService,
    private http: HttpClient,
    private priceService: PriceService,
    private notificationService: UserNotificationService,
    private stockService: StockService,
    private translateService: TranslateService,
    private gaService: GoogleAnalyticsService
  ) { }

  private checkExistance(item: BOrderItem): BOrderItem {
    return this.cart.items.find(i => i.productId === item.productId);
  }

  addToCart(product: Product, amount: number): Observable<any> {
    return this.stockService.isStockAvailable(product.id).pipe(
      mergeMap(stockAvailable => {
        if (!stockAvailable) {
          this.notificationService.sendMessage(
            'notification.product-out-stock',
            { type: 'danger' }
          );
          return of(null);
        }
        const match = this.cart.items.find(item => item.productId === product.id);
        let newAmount = amount;
        if (match) {
          this.removeItem(match);
          newAmount += match.quantity;
        }
        return this.convertToOrderItem(product, newAmount).pipe(
          tap(orderItem => {
            this.addCartItem(orderItem);
            this.notificationService.sendMessage(
              'notification.item-added-to-cart',
              { type: 'success', translationParams: { item: orderItem.name } }
            );
            this.gaService.reportEvent('add_to_cart', {
              value: orderItem.pvp / 100,
              currency: 'EUR',
              items: [
                {
                  item_id: orderItem.productId,
                  item_name: orderItem.name,
                  quantity: orderItem.quantity
                }
              ]
            });
          }),
          map(() => null)
        );
      })
    );
  }

  addCartItem(cartItem: BOrderItem) {
    this.cart.items.push(cartItem);
    this.cart.numberItens = this.cart.items
      .map(item => item.quantity)
      .reduce((sum, quantity) => sum + quantity, 0);
    this.subject.next(this.cart);
  }

  clearCart() {
    this.cart = new Cart();
    this.cart.numberItens = 0;
    this.cart.items = [];
    this.cartUpdateUrl = '';
    this.currentCartId = '';

    this.subject.next(this.cart);
  }

  getCart(): Observable<Cart> {
    return this.subject.asObservable();
  }

  getLatestCart(clientId: string): Observable<Cart> {
    const url = `${environment.shopUrl}/cart/${clientId}/latest`;
    return this.http.get<Cart>(url);
  }

  createCart(clientId: string, cart: Cart): Observable<HttpResponse<any>> {
    const url = `${environment.shopUrl}/cart/${clientId}`;
    return this.http.post<any>(url, cart, { observe: 'response' })
      .pipe(tap(response => {
        this.cartUpdateUrl = response.headers.get('location');
      }));
  }

  updateCartByUrl(url: string, cart: Cart): Observable<HttpResponse<any>> {
    return this.http.put<any>(url, cart);
  }

  private isToKeep(cartItem: BOrderItem, item: BOrderItem): boolean {
    return cartItem.productId !== item.productId;
  }

  removeCartItem(cartItem: BOrderItem) {
    this.removeItem(cartItem);
    this.cart.numberItens -= cartItem.quantity;
    this.subject.next(this.cart);
  }

  private removeItem(cartItem: BOrderItem) {
    const tempArray = this.cart.items.filter(item =>
      this.isToKeep(cartItem, item)
    );
    this.cart.items = tempArray;
  }

  setCart(cart: Cart) {
    this.cart = cart;
    this.subject.next(cart);
  }

  convertToOrderItem(product: Product, amount: number, price?: number): Observable<BOrderItem> {
    return this.priceService.getActiveMultiplePrice(product.id, amount).pipe(
      map(priceRes => this.makeOrderItem(product, amount, priceRes))
    );
  }

  private makeOrderItem(product: Product, amount: number, price: Price): BOrderItem {
    const currentLanguage = this.languageService.currentLanguage.value;
    const desc = this.productService.getTranslatedDescription(product, currentLanguage);
    const item = new BOrderItem();
    item.productId = product.id;
    item.imgId = product.img;
    item.name = this.getCartItemName(product, desc);
    item.description = desc.description;
    item.manufacturer = product.manufacturerId;
    item.pvp = price.discountedPrice;
    item.priceId = price.id;
    item.quantity = amount;
    item.totalPrice = item.pvp * item.quantity;

    return item;
  }

  convertToCartItem(product: Product, amount: number, price: number): CartItem {
    const cartItem = new CartItem();
    cartItem.prd = product;
    cartItem.qtd = amount;
    cartItem.price = price;

    return cartItem;
  }

  extractCartIdFromUrl(url: string): string {
    // URL should look like `https://backend/cart/{clientId}/{cartId}`
    const path = new URL(url).pathname.substring(1);
    const params = path.split('/');
    return params[2];
  }

  getCartItemName(product: Product, description: Description): string {
    const quantity = product.sellingQuantity;
    const packDetails = quantity && quantity > 1
      ? ' | ' + this.translateService.instant('unit-pack', { units: quantity })
      : '';
    return description.name + packDetails;
  }

  requestInStockNotification(productId: string, email?: string): Observable<any> {
    const url = `${this.shopUrl}/productsNotifications`;
    return this.clientService.getClientChanges().pipe(
      first(c => c !== undefined),
      mergeMap(client => {
        const request: ProductNotification = {
          productId: productId,
          email: ''
        };
        if (client) {
          request.clientId = client.id;
        }
        if (email) {
          request.email = email;
        } else if (client) {
          request.email = client.email;
        } else {
          this.notificationService.sendMessage('notification.error', { type: 'danger' });
          return throwError(new Error('No email address'));
        }
        return this.http.post<any>(url, request).pipe(
          catchError(error => {
            this.notificationService.sendMessage('notification.error', { type: 'danger' });
            return throwError(error);
          }),
          tap(() => {
            this.notificationService.sendMessage(
              'notification.request-product-notification-success',
              { type: 'success' }
            );
          })
        );
      })
    );
  }
}
