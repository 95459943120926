<div class="dropdown cursor pl-2" style="height: 1.4em">
  <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="fa fa-truck"></i>
    <span>{{'deliver-to' | translate}} {{selectedDestination.destination}}</span>
    <i class="fa fa-angle-down" aria-hidden="true"></i>
  </span>

  <div class="dropdown-menu" style="margin-left: -4.7em">
    <a class="dropdown-item"
      style="font-size: 0.8rem"
      [ngClass]="{ 'active': shipping.code === selectedDestination.code }"
      *ngFor="let shipping of shippingList"
      (click)="select(shipping)"
    >
      <span class="flag-icon flag-icon-{{shipping.code}}"></span>
      {{shipping.destination}}
    </a>
  </div>
</div>
