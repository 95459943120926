<div class="col main_root" id="top">
  <div class="main_child">
    <app-loading></app-loading>
    <app-header></app-header>
    <app-user-notification></app-user-notification>
    <div class="outletContainer h-100 w-100" id="outletContainerID">
      <app-darken-overlay></app-darken-overlay>
      <router-outlet class="mmm"></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
