import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { Feature } from 'app/models/feature';
import { Product } from 'app/models/product';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  featureUrl = `${environment.baseUrl}/feature`;  // URL to web api
  allFeaturesUrl = `${environment.baseUrl}/feature/list`;

  constructor(
    private http: HttpClient
  ) { }

  get(id: string): Observable<Feature> {
    return this.http.get<Feature>(`${this.featureUrl}/${id}`);
  }

  getAll(): Observable<Feature[]> {
    return this.http.get<Feature[]>(this.allFeaturesUrl);
  }

  getByKey(key: string): Observable<Feature[]> {
    const url = `${this.featureUrl}/key/${key}`;

    return this.http.get<Feature[]>(url);
  }

  getAllChildren(parent: Feature): Observable<Feature[]> {
    const children = parent.childFeaturesId;
    if (children !== undefined && children.length > 0) {
      const obss = children.map(id => {
        return this.get(id).pipe(
          catchError(_ => of(null))
        );
      });
      return forkJoin(obss);
    }
    return of([]);
  }

  getAllChildrenNew(parentFeatureId: string): Observable<Feature[]> {
    return this.http.get<Feature[]>(`${this.featureUrl}/${parentFeatureId}/children`);
  }

  sort(featureList: Feature[]): Feature[] {
    return featureList.sort((f1, f2) => {
      if (f1.sortPosition && f2.sortPosition) {
        return f1.sortPosition - f2.sortPosition;
      }
      return 0;
    });
  }

  getFeaturesInProduct(product: Product): string[] {
    return [].concat(
      product.primaryFeature ? product.primaryFeature.featureId : [],
      product.secondaryFeature ? product.secondaryFeature.featureId : [],
      product.features.filter(f => f !== undefined).map(f => f.featureId)
    );
  }
}
