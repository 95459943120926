<div class="search-preview">
  <div *ngIf="resultNumber > 0">
    <header class="header">
      <p class="result-number">
        <span class="result-number-label">{{'results' | translate}}</span>: {{resultNumber}}
      </p>
    </header>
    <div class="product-list">
      <a *ngFor="let product of products" routerLink="{{product | productUrl | async}}" class="product">
        <img [appStoreImage]="product.img" [size]="'small'" class="product-image">
        <p class="product-name">{{(product.descriptions | objTranslate | async).name}}</p>
        <p>
          <span class="price">
            {{(prices[product.id] !== undefined ? prices[product.id].price : 0) | cost:'EUR'}}
          </span>
          <span class="phantom-price">
            {{(prices[product.id] !== undefined ? prices[product.id].phantomPrice : 0) | cost:'EUR'}}
          </span>
        </p>
      </a>
    </div>
    <div class="footer">
      <a
        [routerLink]="['browse']"
        [queryParams]="{ q: this.query }"
        class="result-page-button"
      >Ver todos os resultados</a>
    </div>
  </div>

  <div *ngIf="resultNumber === 0" class="no-results">{{'no-results' | translate}}</div>
</div>
