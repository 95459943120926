import { Injectable } from '@angular/core';
import { Species } from '../../models/species';
import { environment } from 'environments/environment';
import { BaseRest } from 'rest-lib';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SpeciesService extends BaseRest<any> {


    private speciesUrl = environment.baseUrl + '/admin/species';  // URL to web api

    getUrl(): string {
        return this.speciesUrl;
    }

    constructor(http: HttpClient) {
        super(http);
    }

    getSpecies(): Promise<Species[]> {
        return this.getByUrl('');
    }

}
