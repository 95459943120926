import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from './models/product';
import { ProductsService} from './services/product/products.service';

@Pipe({ name: 'productUrl' })
export class ProductUrlPipe implements PipeTransform {
  constructor(
    private productService: ProductsService
  ) { }

  transform(product: Product): Observable<string> {
    return this.productService.makeUrl(product);
  }
}
