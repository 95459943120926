import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'environments/environment';
import { UserProfileBarComponent } from './components/user-profile-bar/user-profile-bar.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SourceInterceptor } from './source.interceptor';
import { IdTokenInterceptor } from './id-token.interceptor';
import { ReauthOnForbiddenInterceptor } from './reauth-on-forbidden.interceptor';
import { SharedModule } from './shared/shared.module';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

@NgModule({
  declarations: [
    UserProfileBarComponent
  ],
  imports: [
    AppModule,
    BrowserAnimationsModule,
    SharedModule,
    BrowserTransferStateModule,
    AuthModule.forRoot({
      domain: 'hfx.eu.auth0.com',
      clientId: 'dwF63jH1wLC8CGcgSAkhCDx6k4XMjEp1',
      httpInterceptor: {
        allowedList: [
          {
            uri: 'https://backendshop.dev.services.genialoop.com/*'
          },
          {
            uri: 'https://catalogue.dev.services.genialoop.com/*'
          }
        ]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SourceInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IdTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ReauthOnForbiddenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppBrowserModule {}
