<div *ngIf="prods" class="products-r w-100 row">
  <div *ngFor="let product of prods; let i = index"
    [id]="product?.id"
    class="col-12 col-sm-6 col-md-4 col-xl-2 recommended-item text-center"
  >
    <div class="card-top-prod">
      <a routerLink="{{product | productUrl | async}}" class="image-container">
        <img class="card-img-top border border-secondary rounded-1 prodImage"
          [appStoreImage]="product?.img"
          size="small"
          alt="Imagem Inexistente"
        >
      </a>
      <div class="recommended-title font-weight-normal">
        <span class="card-title">{{productTitles.get(product.id) | async}}</span>
      </div>
      <div class="card-body align-self-end">
        <h5 class="card-text font-weight-bold d-inline-block">{{(prices[product?.id] || 0) | cost:'EUR'}}</h5>
        <!-- <span class="text-success">{{product.price | currency:'EUR'}}</span> -->
      </div>
    </div>

    <div class="card-body card-btn align-self-end">
      <button
        class="btn btn-primary add-to-cart w-100"
        (click)="addToCart(product)"
        type="button"
        [disabled]="!stocks[product.id]"
      >Adicionar</button>
    </div>
  </div>
</div>
