import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewsletterService } from '../../services/newsletter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  newsletterForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
  newsletterFormState: 'none' | 'pending' | 'success' | 'error' = 'none';
  submittedEmail = '';

  get email(): FormControl {
    return this.newsletterForm.get('email') as FormControl;
  }

  constructor(
    private newsletterService: NewsletterService
  ) { }

  ngOnInit() {
  }

  submit() {
    const email = this.newsletterForm.value.email;
    this.newsletterFormState = 'pending';
    this.submittedEmail = email;
    this.newsletterService.join(email).subscribe(
      () => this.handleSuccess(),
      () => this.handleError()
    );
  }

  handleSuccess() {
    this.newsletterFormState = 'success';
  }

  handleError() {
    this.newsletterFormState = 'error';
  }
}
