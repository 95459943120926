import { Component, OnInit, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

import { ShippingDestinationService } from 'app/services/shipping-destination.service';

import { Shipping } from 'app/models/shipping';
import { UserNotificationService } from 'app/services/language/user-notification.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
  shippingList: Shipping[];
  selectedDestination: Shipping;
  destinationChanged: EventEmitter<Shipping>;
  flagsUrl = environment.flagsUrl;

  constructor(
    private shippingService: ShippingDestinationService,
    private notificationService: UserNotificationService
  ) {
    this.destinationChanged = new EventEmitter<Shipping>();
  }

  ngOnInit() {
    this.shippingList = this.shippingService.getDestinationList();
    this.selectedDestination = this.shippingService.connectDestinationSelector(this.destinationChanged);
  }

  select(destination: Shipping) {
    if (this.selectedDestination !== destination) {
      if (!destination.available) {
        this.notificationService.sendMessage('notification.destination-under-preparation', { autoDismiss: false });
      } else {
        this.selectedDestination = destination;
        this.destinationChanged.emit(destination);
      }
    }
  }

}
