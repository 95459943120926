import {
  Directive,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
  HostListener
} from '@angular/core';
import { ImageRequestService } from 'app/services/image-request.service';

@Directive({
  selector: '[appStoreImage]'
})
export class StoreImageDirective implements OnChanges {
  @Input('appStoreImage') imageId: string;
  @Input() size: 'small' | 'medium' | 'large' | 'original';

  constructor(
    private el: ElementRef,
    private imageRequest: ImageRequestService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    const imageId = changes['imageId'] ? changes['imageId'].currentValue : this.imageId || null;
    const size = changes['size'] ? changes['size'].currentValue : this.size || 'medium';
    (this.el.nativeElement as HTMLImageElement).setAttribute('loading', 'lazy');
    if (imageId === null) {
      this.el.nativeElement.src = '/assets/images/NO_IMG.png';
    } else if (size === 'original') {
      this.el.nativeElement.src = this.imageRequest.imagesUrl + '/' + imageId;
    } else {
      this.el.nativeElement.src = this.imageRequest.imagesUrl
        + '/' + imageId + '?width=' + this.imageRequest.getWidth(size);
    }
  }

  @HostListener('error') onError() {
    this.el.nativeElement.src = '/assets/images/NO_IMG.png';
  }
}
