import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

import { AuthService } from '@auth0/auth0-angular';
import { UserNotificationService } from './services/language/user-notification.service';
import { ClientService } from './services/user/client.service';
import { NgbModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language/language.service';
import { CartService } from './services/cart/cart.service';
import { environment } from 'environments/environment';
import { Cart } from './models/backend/cart';
import { ImageRequestService } from './services/image-request.service';
import { ServerEventService } from 'app/services/server-event.service';
import { DarkenOverlayService } from './services/darken-overlay.service';
import { LocalStorageService } from './services/local-storage.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { DocumentTitleService } from './services/document-title.service';
import { Meta } from '@angular/platform-browser';
import { PromoPopupComponent } from './components/promo-popup/promo-popup.component';
import { DOCUMENT } from '@angular/common';
import { map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CanonicalUrlService } from './services/canonical-url.service';

declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  promoModal = null;

  constructor(
    private auth: AuthService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private router: Router,
    private _scrollToService: ScrollToService,
    private notifier: UserNotificationService,
    private clientService: ClientService,
    private ngbTypeaheadConfig: NgbTypeaheadConfig,
    private cartService: CartService,
    private imageRequestService: ImageRequestService,
    private serverEventService: ServerEventService,
    private darkenOverlayService: DarkenOverlayService,
    private localStorageService: LocalStorageService,
    private gaService: GoogleAnalyticsService,
    private documentTitleService: DocumentTitleService,
    private metaService: Meta,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document,
    private canonicalUrl: CanonicalUrlService
  ) {
  }


  ngOnInit() {
    this.imageRequestService.init();

    this.clientService.getClientChanges().subscribe(client => {
      const storedCart = this.localStorageService.getItem('cart');

      if (storedCart && !client) {
        this.cartService.setCart(JSON.parse(storedCart));
      } else if (!storedCart && !client) {
        const newCart = new Cart();
        newCart.items = [];
        newCart.numberItens = 0;
        this.cartService.setCart(newCart);
      } else if (storedCart && client) {
        const cart = JSON.parse(storedCart);
        cart.clientId = client.id;
        cart.clientName = client.name;
        this.cartService.setCart(cart);
        this.cartService.createCart(client.id, cart).subscribe(response => {
          this.cartService.cartUpdateUrl = response.headers.get('location');
          this.cartService.currentCartId = this.cartService.extractCartIdFromUrl(this.cartService.cartUpdateUrl);
        });
      } else if (!storedCart && client) {
        this.cartService.getLatestCart(client.id).subscribe(cart => {
          if (cart.items === undefined) {
            cart.items = [];
          }
          this.cartService.setCart(cart);
          this.cartService.cartUpdateUrl = `${environment.shopUrl}/cart/${cart.clientId}/${cart.id}`;
          this.cartService.currentCartId = this.cartService.extractCartIdFromUrl(this.cartService.cartUpdateUrl);
        }, error => {
          if (error.status === 404) {
            const newCart = this.cartService.cart;
            newCart.clientId = client.id;
            newCart.clientName = client.name;
            this.cartService.setCart(newCart);
            this.cartService.createCart(client.id, newCart).subscribe(response => {
              this.cartService.cartUpdateUrl = response.headers.get('location');
              this.cartService.currentCartId = this.cartService.extractCartIdFromUrl(this.cartService.cartUpdateUrl);
            });
          }
        });
      }

      if (client) {
        this.serverEventService.setupClient(client.id).subscribe();
        this.serverEventService.listen('message').subscribe(message => console.log('Message:' + message));
        this.serverEventService.listen('Payment').subscribe(event => {
          if (event.data === 'Paid') {
            this.notifier.sendMessage('notification.payment-received', { type: 'success', autoDismiss: false });
          } else if (event.data === 'Expired') {
            this.notifier.sendMessage('notification.mbway-payment-expired', { type: 'warning', autoDismiss: false });
          }
        });
      }
    });

    this.cartService.getCart().pipe(
      switchMap(cart => {
        if (cart) {
          this.localStorageService.setItem('cart', JSON.stringify(cart));
        }
        if (cart && this.cartService.cartUpdateUrl) {
          return this.cartService.updateCartByUrl(this.cartService.cartUpdateUrl, cart).pipe(
            map(() => cart)
          );
        }
        return of(cart);
      }),
      tap(cart => this.cartService.cartUpdateSubject.next(cart))
    ).subscribe();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.triggerScrollToOffsetOnly();
        this.gaService.reportNavigation(event.url);
      } else if (event instanceof NavigationEnd) {
        // get route data
        let route = this.router.routerState.root;
        while (route.firstChild) {
          route = route.firstChild;
        }
        const data = route.snapshot.data;

        this.setPageTitle(data);

        this.canonicalUrl.set(data['canonical']);
      }
    });

    this.ngbTypeaheadConfig.editable = false;
    this.ngbTypeaheadConfig.placement = ['bottom-left', 'bottom-right'];
    this.translateService.setDefaultLang('pt');
    this.translateService.use('pt');
    this.languageService.currentLanguage.next('pt');

    this.metaService.updateTag({ name: 'description', content: 'genialvet é a loja online para animais de estimação com produtos recomendados por profissionais veterinários | Envio GRÁTIS desde 39,99€ | Entrega 24-48H' });

    const queryParams = this.getInitialQueryParams();

    if (queryParams.get('popup') !== 'no') {
      const popupTimer = this.localStorageService.getItem('next-popup-time');
      const now = Date.now();
      if (popupTimer === null || now > Number(popupTimer)) {
        this.promoModal = this.modalService.open(PromoPopupComponent, { windowClass: 'promo-popup-window' });

        const nextTimestamp = now + environment.popupInterval;
        this.localStorageService.setItem('next-popup-time', nextTimestamp.toString());
      }
    }
  }

  getInitialQueryParams(): URLSearchParams {
    const queryParams = new this.document.defaultView.URLSearchParams(this.document.defaultView.location.search);
    return queryParams;
  }

  setPageTitle(routeData: { title?: string, titleSuffix?: string }): void {
    const title = routeData.title
      ?? 'genialvet - Todos os produtos do seu veterinário, online e ao melhor preço';

    const suffix = routeData.titleSuffix;

    if (suffix !== undefined) {
      this.documentTitleService.setTitle(title);
    } else {
      this.documentTitleService.setTitle(title, suffix);
    }
  } 

  public triggerScrollToOffsetOnly(offset = 0) {
    const config: ScrollToConfigOptions = {
      offset: 0,
      target: 'top',
      duration: 1000,
      easing: 'easeOutElastic',
    };
    this._scrollToService.scrollTo(config);
  }
}
