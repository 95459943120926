<div *ngIf="client && profile" class="dropdown">
  <div class="dropdown-toggle"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    style="height: 100%; width: 100%; display: inline-block"
  >
    <div>
      <img src="{{profile?.picture}}" alt="" class="vetg-icon profile-img img-fluid rounded-circle">
      <div class="icon-info" style="float:right">
        <div class="desc">{{profile?.given_name || profile?.name}}</div>
      </div>
    </div>

    <div class="dropdown-menu dropdown-menu-right">
      <div class="card-header">
        <div class="selected-pet-block">
          <div class="row mb-2">
            <div class="col">
              <img src="{{profile?.picture}}" class="pet-picture vetg-icon img-fluid">
              <span class="pet-name">{{profile?.given_name || profile?.name}}</span>
            </div>
            <div class="pet-profile-block col-auto">
              <a routerLink="/profile/user" class="pet-profile-link">{{'actions.see-profile' | translate}}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="action-list">
        <a routerLink="/profile/user" class="dropdown-item action">{{'actions.edit-profile' | translate}}</a>
        <a routerLink="/orders" class="dropdown-item action">{{'submenu.my-orders' | translate}}</a>
        <button
          type="button"
          (click)="handleLogout()"
          class="dropdown-item action"
        >
          {{'actions.logout' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!(client && profile)" (click)="handleLoginClick()">
  <div style="float:left"><i class="icon-sign-in icon-size" alt="Sign in"></i></div>
  <div class="icon-info" style="float:right">
    <div class="desc">{{'actions.login' | translate}}</div>
  </div>
</div>

<!--
<div class="dropdown" *ngIf="profile">
  <div class="dropdown-toggle"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    style="height:100%;width:100%;display:inline-block"
  >
    <div style="float:left">
      <img [src]="profile.picture" class="vetg-icon profile-img img-fluid rounded-circle">
    </div>
    <div style="float:right" class="icon-info">
      <div class="desc">{{profile.given_name}}</div>
    </div>
  </div>
  <div class="dropdown-menu dropdown-menu-right">
    <div class="card-header" *ngIf="client" style="color:black;background:#cececece">
      <div style="color:black;"><b>Último Login: </b></div>
      <div style="color:black;">{{client.lastLogin | date:'mediumTime':'':'pt'}}</div>
    </div>
    <a class="dropdown-item py-2" routerLink="userprofile">
      <i class="fa fa-user" aria-hidden="true"></i>
      &nbsp;&nbsp;&nbsp; Meu Perfil
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item py-2"
      (click)="publicAuth.logout()"
      href="#"
    >
      <i class="fa fa-sign-out" aria-hidden="true"></i>
      &nbsp;&nbsp; Terminar Sessão
    </a>
  </div>
</div>

<div (click)="publicAuth.login()" *ngIf="!profile">
  <div style="float:left"><i class="icon-sign-in icon-size" alt="Sign in"></i></div>
  <div class="icon-info" style="float:right">
    <div class="desc">Sign in</div>
  </div>
</div>
-->
