import { Component } from '@angular/core';

@Component({
  selector: 'app-cashback-promotion',
  template: `
    <div class="container">
      <h1 class="font-weight-bold my-3">Condições das Campanhas Promocionais</h1>
      <app-info-page page="first-purchase-promotion"></app-info-page>
      <app-info-page page="cashback-promotion"></app-info-page>
    </div>
  `,
  styleUrls: ['./footer-pages/footer-pages-shared.scss'],
  styles: [`
    h1 {
      font-size: 3rem;
    }
  `]
})
export class PromotionTermsComponent { }
