import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'environments/environment';

import { CartService } from '../../../services/cart/cart.service';
import { UserNotificationService } from '../../../services/language/user-notification.service';
import { Price } from 'app/models/price';

import { CartItem } from '../../../models/cart-item';
import { Product } from '../../../models/product';
import { PriceService } from 'app/services/price/price.service';
import { StockService } from 'app/services/stock/stock.service';
import { ProductsService } from 'app/services/product/products.service';

@Component({
  selector: 'app-products-lines',
  templateUrl: './products-lines.component.html',
  styleUrls: ['./products-lines.component.scss']
})
export class ProductsLinesComponent implements OnInit, OnChanges {
  @Input() prods: Product[];

  prices = {};
  stocks = {};

  currentCountry = 'pt';
  count = 5;
  imagesUrl = environment.baseUrlServices;
  productTitles = null;

  constructor(
    private cartService: CartService,
    private notificationService: UserNotificationService,
    private priceService: PriceService,
    private stockService: StockService,
    private productService: ProductsService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    const products = changes['prods'].currentValue;
    products.forEach(product => {
      const priceRequest = this.priceService.getPrice(product.id, this.currentCountry);
      priceRequest.subscribe(price => {
        this.prices[product.id] = price.price;
      }, error => {
        console.log('Product ' + product.id + ': Price error.');
      });
    });
    this.stockService.getStockAvailableMap(products.map(p => p.id))
      .subscribe(stocks => this.stocks = stocks);
    this.productTitles = new Map(products.map(product => [product.id, this.productService.getTitle(product)]));
  }

  addToCart(prd: Product) {
    this.cartService.addToCart(prd, 1).subscribe();
  }
}
