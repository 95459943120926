import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class TokenService {
  private token: BehaviorSubject<string>;

  constructor() {
    this.token = new BehaviorSubject<string>('');
  }

  registerToken(clientToken: string): void {
    this.token.next(clientToken);
  }

  clearToken(): void {
    this.token.next('');
  }

  getToken(): Observable<string> {
    return this.token.asObservable();
  }
}
