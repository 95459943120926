import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CartService } from '../../../services/cart/cart.service';
import { OrderService } from '../../../services/order/order.service';

import { Cart } from 'app/models/backend/cart';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  cart: Cart;
  showCartDetail = false;
  subscription: Subscription;

  constructor(
    private cartService: CartService,
    private orderService: OrderService
  ) {
    this.cart = this.cartService.cart;
  }

  ngOnInit() {
    this.subscription = this.cartService.getCart().subscribe(cart => {
      this.cart = cart;
    });
  }

  cartDetail() {
    if (this.cart && this.cart.items.length > 0) {
      this.showCartDetail = !this.showCartDetail;
    }
  }

  cartClear() {
    this.cartService.clearCart();
  }

  // endOrder() {
  //   this.orderService.createOrder(this.cart);
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
