import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shop-benefit-block',
  templateUrl: './shop-benefit-block.component.html',
  styleUrls: ['./shop-benefit-block.component.scss']
})
export class ShopBenefitBlockComponent {
  @Input() image = '';
  @Input() benefit = '';
  @Input() message = '';
}
