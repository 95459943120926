import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

interface CostPipeOptions {
  currencyCode?: string;
  display?: string | boolean;
  digitsInfo?: string;
  locale?: string;
}

@Pipe({ name: 'cost' })
export class CostPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) { }

  // args as string for compatibility with current usage (`cost:'EUR'`)
  transform(input: number, args?: string | CostPipeOptions) {
    const cost = input / 100;

    let locale = 'pt';
    let result = '';

    if (args === undefined) {
      result = this.currencyPipe.transform(cost, undefined, undefined, undefined, locale);
    } else if (typeof args === 'string') {
      result = this.currencyPipe.transform(cost, args, undefined, undefined, locale);
    } else {
      locale = args.locale ?? locale;
      result = this.currencyPipe.transform(cost, args.currencyCode,
        args.display, args.digitsInfo, locale);
    }

    return result;
  }
}
