<footer class="footer pr-3 pl-3 pb-4">
  <!--
  <div class="row">
    <div class="col-12 register-clinic-wrapper">
      <a routerLink="/clinic-registration" class="register-clinic-link">{{'register-clinic-prompt' | translate}}</a>
    </div>
  </div>
  -->

  <div class="row py-2">
    <div class="col-6 col-sm-3 col-lg-2 p-2">
      <h4 class="footer-list-title">Informações</h4>
      <ul class="footer-list">
        <li class="footer-list-item">
          <a routerLink="/about-us">Sobre Nós</a>
        </li>
        <li class="footer-list-item">
          <a routerLink="/privacy-policy">Política de Privacidade</a>
        </li>
        <li class="footer-list-item">
          <a routerLink="/general-terms">Condições Gerais</a>
        </li>
        <li class="footer-list-item">
          <a href="https://www.livroreclamacoes.pt" target="blank" title="Livro de Reclamações">Livro de Reclamações</a>
        </li>
      </ul>
    </div>

    <div class="col-6 col-sm-3 col-lg-2 p-2">
      <h4 class="footer-list-title">Entregas</h4>
      <ul class="footer-list">
        <li class="footer-list-item">
          <a routerLink="/delivery-terms">Condições de envio</a>
        </li>
        <li class="footer-list-item">
          <a routerLink="/return-policy">Política de Devolução</a>
        </li>
      </ul>
    </div>

    <div class="col-6 col-sm-3 col-lg-2 p-2">
      <h4 class="footer-list-title">Pagamentos</h4>
      <ul class="footer-list">

        <li class="footer-list-item">
          <a routerLink="/payment-methods">Métodos de pagamento</a>
        </li>
        <li class="footer-list-item" style="line-height:1em !important">&nbsp;
        </li>
        <li class="footer-list-item">
          <img src="/assets/images/icons/payment.png" width="120" height="36">
        </li>

      </ul>
    </div>

    <div class="col-6 col-sm-3 col-lg-2 p-2">
      <h4 class="footer-list-title">Contacte-nos</h4>
      <ul class="footer-list">
        <li class="footer-list-item">
          <a routerLink="/faqs">Perguntas frequentes</a>
        </li>
        <li class="footer-list-item">
          <a href="mailto:store@genial.vet">store@genial.vet</a>
        </li>
        <li class="footer-list-item " style="line-height: 1.25em !important ; margin-top: 0.37em; margin-bottom: 0.38em;">
          <a href="tel:+351912576332">+351 912 576 332 <br>
            <span class="legalPhone" >(chamada para a rede móvel nacional)</span>
          </a>
        </li>
        <li class="footer-list-item">
          <a routerLink="/contact-us">Contactos</a>
        </li>
      </ul>
    </div>

    <div class="col-6 p-2 col-sm-3 col-lg-1">
      <h4 class="footer-list-title">Siga-nos</h4>
      <ul class="footer-list d-flex">
        <li class="footer-list-item">
          <a href="https://www.facebook.com/genialvet/" target="_blank">
            <i class="fa fa-facebook-square facebookFollow" aria-hidden="true"></i>
          </a>
        </li>
        <li class="footer-list-item">
          <a href="https://www.instagram.com/genialvet/" target="_blank">
            <i class="fa fa-instagram ml-2 facebookFollow" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </div>

    <div class="col-12 col-sm-6 col-lg-3 col-xl-3 p-2">
      <h4 class="footer-list-title">Newsletter</h4>
      <ul class="footer-list">
        <li class="footer-list-item" style="font-size: 1.35em;
        line-height: 1.5em !important;
        font-weight: 300;
    }">Subscreva a nossa newsletter e seja o primeiro a saber das melhores novidades para o seu pet!</li>
        <li class="footer-list-item">
          <form [formGroup]="newsletterForm" (ngSubmit)="submit()">
            <div class="input-group mb-3">
                           <input
                type="email"
                class="form-control"
                formControlName="email"
                placeholder="Email"
                aria-label="Subscrição newsletter"
                aria-describedby="basic-addon2"
              >
              <div class="input-group-append">
                <button
                  type="submit"
                  class="btn btn-outline-primary newsletterBtn"
                  [disabled]="!newsletterForm.valid"
                >
                  Submeter
                </button>
              </div>
            </div>

            <p class="newsletter-form-result">
              <span *ngIf="newsletterFormState === 'pending'">
                {{'newsletter-form.pending' | translate}}
              </span>
              <span *ngIf="newsletterFormState === 'success'">
                <i class="fa fa-check"></i>
                {{'newsletter-form.success.0' | translate}}
                {{'newsletter-form.success.1' | translate:({ email: this.submittedEmail })}}
                {{'newsletter-form.success.2' | translate}}
              </span>
              <span *ngIf="newsletterFormState === 'error'">
                <i class="fa fa-warning"></i>
                {{'newsletter-form.error' | translate}}
              </span>
            </p>
          </form>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <p class="text-center text-white-50 mb-0">Copyright © genialvet All rights reserved ® 2022</p>
    </div>
  </div>
</footer>
