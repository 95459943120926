<div id="notifyUser" class="w-100">
  <div
    *ngFor="let notification of notifications"
    class="alert alert-dismissible fade show"
    [ngClass]="{
      'alert-warning': notification.type === 'warning',
      'alert-success': notification.type === 'success',
      'alert-danger': notification.type === 'danger'
    }"
    role="alert"
  >
    <div class="d-flex flex-row">
      <div class="pr-4">
        <i class="fa fa-bell"></i>
      </div>
      <div>
        <b [innerHTML]="notification.message"></b>
      </div>
      <div class="ml-auto">
        {{notification.date | date:'HH\'h\'mm'}}
      </div>
      <div (click)="dismiss(notification)" class="close-button">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </div>
</div>


