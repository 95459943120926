import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { BlogPost } from '../models/blog-post';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  apiRoot = 'https://www.googleapis.com/blogger/v3';
  blogId = '488865624550656255';

  constructor(
    private http: HttpClient
  ) { }

  getPosts(): Observable<BlogPost[]> {
    const url = `${this.apiRoot}/blogs/${this.blogId}/posts?key=${environment.blogApiKey}`;
    return this.http.get<any>(url).pipe(
      map(blogData => blogData['items']),
      map(posts => {
        const parser = new DOMParser();
        return posts.map((post: any) => {
          const doc = parser.parseFromString(post['content'], 'text/html');
          return {
            title: post['title'],
            published: post['published'],
            image: this.getImageUrl(doc),
            preview: this.getPreview(doc),
            url: post['url']
          } as BlogPost;
        })
      })
    );
  }

  private getImageUrl(doc: Document): string {
    return doc.querySelector('img').src;
  }

  private getPreview(doc: Document): string {
    return doc.querySelector('p').textContent;
  }
}