import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retryWhen, mergeMap, tap, catchError, concatMap, map } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class ReauthOnForbiddenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
          return this.authService.idTokenClaims$.pipe(
            mergeMap(token => next.handle(req.clone({ setHeaders: { Authorization: token.__raw } })))
          );
        } else {
          return throwError(error);
        }
      })
    );
  }
}
