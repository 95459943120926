import { BOrderItem } from './b-order-item';
import { DiscountPublic } from './discount-public';
export class Cart {
  id: string;
  clientId: string;
  clientName: string;
  contact: string;
  numberItens: number;
  items: BOrderItem[];
  discountPublicTOs: DiscountPublic[];
}
