import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  state = new BehaviorSubject<boolean>(false);

  constructor() { }

  getState(): Observable<boolean> {
    return this.state.asObservable();
  }

  enable(): void {
    this.state.next(true);
  }

  disable(): void {
    this.state.next(false);
  }
}
