<div class="dropdown show cursor pl-2" style="height: 1.4em">
  <a class="dropdown-toggle current-language"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="fa fa-flag"></i>
    <span>{{selectedLanguage.language}}</span>
    <i class="fa fa-angle-down" aria-hidden="true"></i>
  </a>

  <div class="dropdown-menu" style="margin-left: -4.9em">
    <a class="dropdown-item"
      style="font-size: 0.9rem;"
      [ngClass]="{ 'active': language.code === active }"
      *ngFor="let language of languagesList"
      (click)="select(language,$event);active = language.code"
    >
      <span class="flag-icon flag-icon-{{language.code}}"></span>
      {{language.language}}
    </a>
  </div>
</div>
