<div *ngIf="client">
  <div *ngIf="selectedPet" class="dropdown">
    <div class="dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      style="height: 100%; width: 100%; display: inline-block"
    >
      <div>
        <img [src]="petImage" alt="" class="vetg-icon profile-img img-fluid rounded-circle">
        <div class="icon-info" style="float:right">
          <div class="desc">{{selectedPet.name}}</div>
        </div>
      </div>

      <div *ngIf="selectedPet" class="dropdown-menu dropdown-menu-right">
        <div class="card-header">
          <div class="selected-pet-block">
            <div class="row mb-2">
              <div class="col">
                <img [src]="petImage" class="pet-picture vetg-icon img-fluid">
                <span class="pet-name">{{selectedPet.name}}</span>
              </div>
              <div class="pet-profile-block col-auto">
                <a
                  routerLink="profile/pets/{{selectedPet.id}}"
                  class="pet-profile-link"
                >
                  {{'actions.see-profile' | translate}}
                </a>
              </div>
            </div>
            <div class="row clinic-name-block">
              <div class="col-12 clinic-name">{{selectedPet.clinicName}}</div>
            </div>
          </div>
        </div>

        <div class="action-list" *ngIf="client && selectedPet">
          <button
            *ngFor="let pet of client.pets"
            type="button"
            class="dropdown-item action"
            [ngClass]="{ 'selected': selectedPet.id === pet.id }"
            (click)="select(pet)"
          >
            {{pet.name}}
          </button>
        </div>
        <div class="action-list">
          <a routerLink="/new-pet" class="dropdown-item action">{{'actions.add-pet' | translate}}</a>
          <a
            routerLink="/profile/pets/{{selectedPet?.id}}"
            class="dropdown-item action"
          >
            {{'actions.manage-pets' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!selectedPet" class="mouseHover">
    <a routerLink="/new-pet" class="add-pet-link mouseHover">
      <div style="float:left">
        <i class="vetg-icon img-fluid icon-registo-animal icon-size" style="margin-right: 0.2em"></i>
      </div>
      <div class="icon-info" style="float:right">
        <div class="desc">Adicionar animal</div>
      </div>
    </a>
  </div>
</div>
