import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { concat, of, Observable, Subscription } from 'rxjs';

@Pipe({ name: 'localizedDate' })
export class LocalizedDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) { }

  transform(value: any, pattern: string = 'mediumDate'): Observable<string> {
    return concat(
      of(this.translateService.currentLang),
      this.translateService.onLangChange.pipe(map(e => e.lang))
    ).pipe(
      map(lang => this.datePipe.transform(value, pattern, undefined, lang))
    );
  }
}
