import { Injectable, OnDestroy, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Subscription, Observable, BehaviorSubject, throwError, merge, forkJoin } from 'rxjs';
import { filter, mergeMap, take, switchMap, map } from 'rxjs/operators';
import { BaseRest } from 'rest-lib';
import { environment } from 'environments/environment';

import { TokenService } from './token.service';
import { AuthService } from '@auth0/auth0-angular';
import { UserNotificationService } from '../language/user-notification.service';

import { Client } from 'app/models/client';

@Injectable()
export class ClientService extends BaseRest<any> implements OnDestroy, OnChanges {
  private userUrl = environment.shopUrl + '/clients';  // URL to web api

  private client: BehaviorSubject<Client>;
  currentClient: Client;
  private clientToken: string;
  subscription: Subscription;

  constructor(
    public http: HttpClient,
    private auth: AuthService,
    private tokenService: TokenService,
    private notifier: UserNotificationService,
  ) {
    super(http);
    this.client = new BehaviorSubject<Client>(undefined);
    this.auth.user$.subscribe(profile => {
      if (profile) {
        this.requestClient(profile.sub).subscribe(client => {
          this.registerClient(client);
          if (client.clinicId === undefined) {
            this.notifier.sendMessage('notification.clinic-link-alert');
          }
        }, () => {
          this.registerClient(null);
          this.notifier.sendMessage('notification.error', { type: 'danger' });
        });
      } else {
        this.registerClient(null);
      }
    });
    this.subscription = this.tokenService.getToken().pipe(
      filter(token => token && token.length > 0)
    ).subscribe(clientToken => {
      this.clientToken = clientToken;
    });
  }

  getUrl(): string {
    return this.userUrl;
  }

  registerClient(client: Client): void {
    this.client.next(client);
    this.currentClient = client;
  }

  reloadClient(): Observable<any> {
    return this.auth.user$.pipe(
      take(1),
      switchMap(profile => this.requestClient(profile.sub)),
      map(client => this.registerClient(client))
    );
  }

  getClient(): Observable<Client> {
    return this.client.asObservable().pipe(filter(client => client != null));
  }

  getClientChanges(): Observable<Client> {
    return this.client.asObservable();
  }

  requestClient(id: string): Observable<Client> {
    const params = new URLSearchParams();
    params.append('externalId', id);
    return from(this.queryOne(params, ''));
  }

  saveClient(client: Client): Observable<any> {
    const url = `${this.getUrl()}/${client.id}`;

    return this.http.put(url, client);
  }

  getProfile(): any {
    const profile = localStorage.getItem('profile');
    if (profile) {
      return JSON.parse(profile);
    } else {
      return null;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  ngOnChanges() {
    this.requestClient(this.clientToken);
  }
}
