import { Component, OnInit } from '@angular/core';
import { Cart } from '../../../models/cart';
import { OrderService } from '../../../services/order/order.service';
import { Subscription } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription/subscription.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  cart: Cart;
  subscription: Subscription;

  constructor(private subsService: SubscriptionService, private orderService: OrderService) {
    this.cart = this.subsService.subcart;
  }

  ngOnInit() {
    this.subscription = this.subsService.getCart().subscribe(subcart => {
      this.cart = subcart;
    })
  }

}
