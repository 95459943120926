export const environment = {
  production: true,
  baseUrl: 'https://catalogue.services.genial.vet',
  shopUrl: 'https://backendshop.services.genial.vet',
  imagesUrl: 'https://images.genial.vet',
  baseUrlServices: 'https://services.services.genial.vet',
  adminUrl: 'https://backendadmin.services.genial.vet/pub',
  paymentUrl: 'https://paygateway.services.genial.vet',
  flagsUrl: 'https://lipis.github.io/flag-icon-css/flags/4x3/',
  eventSourceUrl: 'https://eventsource.services.genial.vet/',
  authRedirect: 'https://genial.vet',
  origin: 'https://genial.vet',
  activeHosts: ['genialoop.com'],
  features: {
    subscription: false
  },
  popupInterval: 8 * 60 * 60 * 1000, // 8 hours
  blogApiKey: 'AIzaSyBsYjqvOJDfMsmO1qrvug95yMUwikg53LM',

  authorizedDomains: [
    'catalogue.services.genial.vet',
    'backendshop.services.genial.vet',
    'services.services.genial.vet',
    'backendadmin.services.genial.vet',
    'paygateway.services.genial.vet'
  ]
};
