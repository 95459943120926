<div class="row my-3">
  <div *ngFor="let post of posts | async | slice:0:4" class="col-12 col-md-6 col-xl-3">
    <article class="mb-5">
      <header class="mb-3">
        <div class="mb-3">
          <img [src]="post.image" width="240" height="100" class="image">
        </div>
        <h1 class="h4">
          <a [href]="post.url">{{post.title}}</a>
        </h1>
        <time [dateTime]="post.published" class="h6">
          {{post.published | localizedDate | async}}
        </time>
      </header>
      <p class="preview">{{post.preview | truncate:100}}</p>
      <a [href]="post.url" class="d-block">Ver mais <i class="fa fa-external-link"></i></a>
    </article>
  </div>
</div>
