<div class="dropdown mouseHover" routerLink="/cart">
  <div class="dropdown-toggle cart-menu">
    <div><i class="icon-carrinho icon-size"></i></div>
    <div class="icon-info">
      <div *ngIf="cart then totaItens else zeroItems">
    </div>
    <ng-template #totaItens>
      <div class="info">{{cart.numberItens}}</div>
    </ng-template>
    <ng-template #zeroItems>
      <div class="info">0</div>
    </ng-template>
    <div class="desc">{{'header.cart' | translate}}</div></div>
  </div>
</div>
