import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { SpeciesService } from '../../../services/animal/species.service';
import { FeatureService } from 'app/services/feature.service';

import { Feature } from 'app/models/feature';
import { DarkenOverlayService } from 'app/services/darken-overlay.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [SpeciesService]
})
export class SearchBarComponent implements OnInit {
  selectedSpecies: Feature = null;
  species: Feature[];
  query = '';
  searchPreviewQuery = '';
  previewVisible = false;
  searchControl = new FormControl('');

  constructor(
    private speciesService: SpeciesService,
    private featuresService: FeatureService,
    private router: Router,
    private route: ActivatedRoute,
    private darkenOverlayService: DarkenOverlayService
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      const query = params.get('q');
      if (query !== null) {
        this.query = query;
      }
    });

    this.searchControl.valueChanges.pipe(
      tap(q => this.query = q),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(query => this.searchPreviewQuery = query);

    this.searchControl.valueChanges
      .subscribe(query => {
        if (query !== '') {
          this.showSearchPreview();
        } else {
          this.hideSearchPreview();
        }
      });

    this.featuresService.getByKey('species').subscribe(speciesParent => {
      this.featuresService.getAllChildren(speciesParent[0]).subscribe(species => {
        this.species = species;
      });
    });

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.hideSearchPreview();
      }
    });

    this.darkenOverlayService.userClicks()
      .subscribe(() => this.hideSearchPreview());
  }

  search(): void {
    const queryParams = {
      q: this.query
    };
    if (this.selectedSpecies !== null) {
      queryParams['f'] = this.selectedSpecies.key.replace('species-', '');
    }
    this.router.navigate(['/browse'], { queryParams });
  }

  onSpeciesSelect(species: Feature): void {
    this.selectedSpecies = species;
  }

  onSearchInput() {
    if (this.query !== '') {
      this.showSearchPreview();
    } else {
      this.hideSearchPreview();
    }
  }

  showSearchPreview() {
    this.darkenOverlayService.enable();
    this.previewVisible = true;
  }

  hideSearchPreview() {
    this.darkenOverlayService.disable();
    this.previewVisible = false;
  }
}
