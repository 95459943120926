import { Component } from '@angular/core';
import { DarkenOverlayService } from 'app/services/darken-overlay.service';

@Component({
  selector: 'app-darken-overlay',
  templateUrl: './darken-overlay.component.html',
  styleUrls: ['./darken-overlay.component.scss']
})
export class DarkenOverlayComponent {
  state = this.darkenOverlayService.getState();

  constructor(
    private darkenOverlayService: DarkenOverlayService
  ) { }

  onOverlayClick() {
    this.darkenOverlayService.broadcastClick();
  }
}
