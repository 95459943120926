import { Injectable } from '@angular/core';
import { Subcart } from '../../models/subcart';
import { Subject, Observable } from 'rxjs';
import { SubcartItem } from '../../models/subcart-item';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class SubscriptionService {

  subcart: Subcart;
  private subject = new Subject<Subcart>();
  constructor(
    private localStorageService: LocalStorageService
  ) {
    let oldCart = null;
    try {
      oldCart = JSON.parse(this.localStorageService.getItem('subcart'));
    } catch (e) {
      if (e instanceof SyntaxError) {
        oldCart = null;
      }
    }
    this.subcart = oldCart;
    if (!oldCart) {
        this.subcart = new Subcart();
        this.subcart.total = 0;
        this.subcart.totalItens = 0;
        this.subcart.cartItems = new Array();
    }
    this.subject.next(this.subcart);
    this.subject.subscribe(cart => {
        this.localStorageService.setItem('subcart', JSON.stringify(cart));
    })
   }


   private checkExistance(cartItem: SubcartItem): SubcartItem[] {
    const qtd = 0;
    const exist = this.subcart.cartItems.filter(item => {
        if (item.prd.id === cartItem.prd.id) {
            return item.qtd;
        }
    }); return exist;
}

addCartItem(cartItem: SubcartItem) {
  const result = this.checkExistance(cartItem);
  if (result.length > 0) {
      console.log('EXISTS? ', true);
      this.removeItem(cartItem);
      cartItem.qtd = cartItem.qtd + result[0].qtd;
  } else {
      console.log('EXISTS? ', false);
  }

  const totalItens = this.subcart.cartItems.push(cartItem);
  this.subcart.totalItens = totalItens;
  this.subcart.total = this.subcart.cartItems.reduce((previousValue, item) => previousValue + item.price * item.qtd
      , 0);
  this.subject.next(this.subcart);
}

clearCart() {
  this.subcart = new Subcart();
  this.subcart.total = 0;
  this.subcart.totalItens = 0;
  this.subcart.cartItems = [];

  this.subject.next(this.subcart);
}

getCart(): Observable<Subcart> {
  return this.subject.asObservable();
}

private isToKeep(cartItem: SubcartItem, item: SubcartItem): boolean {
  return cartItem.prd.id !== item.prd.id;
}
removeCartItem(cartItem: SubcartItem) {
  this.subcart.total = (this.subcart.total - (cartItem.price * cartItem.qtd));
  this.removeItem(cartItem);
  this.subcart.totalItens = this.subcart.totalItens - 1;
  this.subject.next(this.subcart);
}
private removeItem(cartItem: SubcartItem) {
  const tempArray = this.subcart.cartItems.filter(item =>
      this.isToKeep(cartItem, item)
  )
  this.subcart.cartItems = tempArray;
}
updateCartItem(cartItem: SubcartItem) {
  this.removeItem(cartItem);
  const totalItens = this.subcart.cartItems.push(cartItem);
  this.subcart.totalItens = totalItens;
}

setCart(cart: Subcart) {
  this.subject.next(cart);
}


}
