import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { Observable } from 'rxjs';
import { BlogPost } from '../../models/blog-post';

@Component({
  selector: 'app-blog-feed',
  templateUrl: './blog-feed.component.html',
  styleUrls: ['./blog-feed.component.scss']
})
export class BlogFeedComponent implements OnInit {
  posts: Observable<BlogPost[]> = null;

  constructor(
    private blogService: BlogService
  ) { }

  ngOnInit(): void {
    this.posts = this.blogService.getPosts();
  }
}
