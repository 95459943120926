import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DocumentTitleService {
  constructor(
    private titleService: Title
  ) { }

  setTitle(title: string, suffix: string = ' - genialvet'): void {
    this.titleService.setTitle(title + suffix);
  }

  getTitle(): string {
    return this.titleService.getTitle();
  }
}
