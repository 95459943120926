import { Component, OnInit } from '@angular/core';

import { ClientService } from 'app/services/user/client.service';

import { Client } from 'app/models/client';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  client: Client;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });
  }

}
