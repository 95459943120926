import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, mergeMap, reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() { }

  partition(arr: any[], pred: (item: any) => boolean): any[] {
    return arr.reduce((result, value) => {
      if (pred(value)) {
        return [result[0].concat(value), result[1]];
      } else {
        return [result[0], result[1].concat(value)];
      }
    }, [[], []]);
  }

  partitionAsync(arr: any[], pred: (item: any) => Observable<boolean>): Observable<any[]> {
    return from(arr).pipe(
      mergeMap(item => pred(item).pipe(map(answer => ({ item, answer })))),
      reduce((result: any[][], value: { item: any, answer: boolean }) => {
        if (value.answer) {
          return [result[0].concat(value.item), result[1]];
        } else {
          return [result[0], result[1].concat(value.item)];
        }
      }, [[], []])
    );
  }

  /*takeRandomOld<T>(list: T[], howMany = 1): T[] {
    const randomNum = (limit: number) => Math.floor(Math.random() * limit);
    const removeByIndex = (array: any[], index: number) => [].concat(array.slice(0, index), array.slice(index + 1));
    const takeRandomRec = (_list: any[], _howMany = 1, result: any[]): any[] => {
      if (_howMany <= 0) {
        return result;
      } else {
        const index = randomNum(_howMany);
        const item = _list[index];
        return takeRandomRec(
          removeByIndex(_list, index),
          _howMany - 1,
          result.concat(item)
        );
      }
    }

    const elementsToTake = Math.min(list.length, howMany);
    return takeRandomRec(list, elementsToTake, []);
  }*/

  takeRandom<T>(array: T[], amount = 1): T[] {
    if (amount <= 0 || array.length <= 0) {
      return [];
    }
    const index = Math.floor(Math.random() * array.length);
    const el = array[index];
    const remaining = [...array.slice(0, index), ...array.slice(index + 1)];
    return [el].concat(this.takeRandom(remaining, amount - 1));
  }

  unique(array: any[]) {
    return Array.from(new Set(array));
  }

  normalizeString(s: string) {
    return s
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
}
