<nav class="navbar navbar-expand-sm navbar-dark px-3 pl-lg-0" style="background:#00574c">
  <div class="d-flex">
    <ul class="navbar-nav flex-row">
      <li class="nav-item">
        <a
          routerLink="/browse"
          [queryParams]="{ s: 'species-dog' }"
          routerLinkActive="active"
          class="nav-link vetg-nav-link mr-4 mr-sm-3"
        >
          {{'navbar.item.dog' | translate}}
        </a>
      </li>

      <li class="nav-item">
        <a
          routerLink="/browse"
          [queryParams]="{ s: 'species-cat' }"
          routerLinkActive="active"
          class="nav-link vetg-nav-link mr-3"
        >
          {{'navbar.item.cat' | translate}}
        </a>
      </li>

      <li class="nav-item">
        <a
          routerLink="/browse"
          [queryParams]="{ s: 'species-other' }"
          routerLinkActive="active"
          class="nav-link vetg-nav-link mr-sm-3"
        >
          {{'navbar.item.others' | translate}}
        </a>
      </li>
    </ul>
  </div>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav d-flex mt-3 mt-sm-0">
      <li class="nav-item">
        <a routerLink="/brands" routerLinkActive="active" class="nav-link vetg-nav-link mr-sm-3">
          {{'navbar.item.brands' | translate}}
        </a>
      </li>

      <li *ngIf="activeClient && activeClient.pets && activeClient.pets.length > 0" class="nav-item">
        <a
          routerLink="/browse/pet"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="nav-link vetg-nav-link mr-sm-3"
        >
          {{'navbar.item.highlights' | translate}}
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link my-vet vetg-nav-link mr-sm-3"
          routerLink="/profile/clinic"
          routerLinkActive="active"
        >
          {{'navbar.item.myvet' | translate}}
        </a>
      </li>
    </ul>

    <div class="subsVet form-inline my-2 my-lg-0" style="margin-right:-1.8em">
      <div [appDisplayIfFeatureOn]="'subscription'" class="nav-item mr-sm-2">
        <a routerLink="/subscription" routerLinkActive="active" class="nav-link">
          {{'navbar.item.subscription' | translate}}
        </a>
      </div>
    </div>
  </div>
</nav>
