<div class="dropdown mouseHover" routerLink="/subscription">
  <div  class="dropdown-toggle" >
      <div style="float:left"><i  class="icon-subscrições icon-size"></i></div>
      <div class="icon-info" style="float:right">
          <div *ngIf="cart then totaItens else zeroItems">

      </div>
      <ng-template #totaItens><div class="info">{{cart.totalItens}}</div></ng-template>
      <ng-template #zeroItems><div class="info">0</div></ng-template>
      <div class="desc">{{'header.subscriptions' | translate}}</div></div>
  </div>


  </div>
