export class BOrderItem {
  name: string;
  description: string;
  pvp: number;
  totalPrice: number;
  vatTax: number;
  quantity: number;
  discountValue: number;
  totalDiscountValue: number;
  imgId: string;
  productId: string;
  manufacturer: string;
  priceId: string;
}
