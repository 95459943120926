import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from 'app/models/product';
import { ProductsService } from 'app/services/product/products.service';
import { from } from 'rxjs';
import { ProductSearchService } from 'app/services/product-search/product-search.service';
import { PriceService } from 'app/services/price/price.service';
import { ShippingDestinationService } from 'app/services/shipping-destination.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-search-preview',
  templateUrl: './search-preview.component.html',
  styleUrls: ['./search-preview.component.scss']
})
export class SearchPreviewComponent implements OnInit, OnChanges {
  @Input() query: string;

  products: Product[] = [];
  prices = {};
  resultNumber = 0;

  constructor(
    private productService: ProductsService,
    private productSearcher: ProductSearchService,
    private priceService: PriceService,
    private destinationService: ShippingDestinationService
  ) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    const query = changes.query.currentValue;
    if (query.length >= 3) {
      this.updateList(query);
    }
  }

  updateList(query: string): void {
    this.destinationService.getDestination().pipe(
      mergeMap(d => this.productService.getByCountry(d.code))
    ).subscribe(products => {
      const searchResult = this.productSearcher.search(products, query, 'pt');
      this.resultNumber = searchResult.length;
      this.products = searchResult.slice(0, 9);
      this.priceService.getActivePrices(this.products.map(p => p.id))
        .subscribe(prices => this.prices = prices);
    });
  }
}
