<div *ngIf="!isLoading">
  <app-promotion-notice></app-promotion-notice>
  <app-slideshow></app-slideshow>
  <br>
  <app-top-ads></app-top-ads>
  <br>
  <div class="row middle-info pt-3 pb-4">
    <div class="row w-side-margins w-100">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <h4 style="color:#008675 !important" class="p-3 font-weight-bold">Produtos Recomendados</h4>
        <app-products-lines [prods]="products"></app-products-lines>
      </div>
    </div>
  </div>

  <div class="row pt-3 pb-4">
    <div class="row w-side-margins w-100">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-4">
        <app-products-grids [prods]="grid" [lastProds]="recomended"></app-products-grids>
      </div>
    </div>
  </div>

  <div class="row w-side-margins">
    <app-brands-slide class="w-100"></app-brands-slide>
  </div>

  <div class="px-4 mb-5">
    <h4 style="color:#008675 !important" class="p-3 font-weight-bold">
      Blog
    </h4>
    <app-blog-feed></app-blog-feed>
  </div>

  <div class="row justify-content-center">
    <div class="col col-lg-3">
      <app-shop-benefit-block
        image="https://assets.genial.vet/free-shipping.png"
        benefit="shop-benefits.free-shipping.title"
        message="shop-benefits.free-shipping.message"
      ></app-shop-benefit-block>
    </div>
    <div class="col col-lg-3">
      <app-shop-benefit-block
        image="https://assets.genial.vet/vet.png"
        benefit="shop-benefits.my-vet.title"
        message="shop-benefits.my-vet.message"
      ></app-shop-benefit-block>
    </div>
    <div class="col col-lg-3">
      <app-shop-benefit-block
        image="https://assets.genial.vet/lock.png"
        benefit="shop-benefits.safety.title"
        message="shop-benefits.safety.message"
      ></app-shop-benefit-block>
    </div>
    <div class="col col-lg-3">
      <app-shop-benefit-block
        image="https://assets.genial.vet/cashback.svg"
        benefit="shop-benefits.cashback.title"
        message="shop-benefits.cashback.message"
      ></app-shop-benefit-block>
    </div>
  </div>
</div>
