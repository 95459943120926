<div class="mouseHover favorites-menu" routerLink="/favorites">
  <div><i class="icon-favoritos icon-size"></i></div>
  <div class="icon-info">
    <div *ngIf="cart; then totalItems else zeroItems"></div>
    <ng-template #totalItems>
      <div class="info">{{cart.totalItens}}</div>
    </ng-template>
    <ng-template #zeroItems>
      <div class="info">0</div>
    </ng-template>
    <div class="desc">{{'header.favorites' | translate}}</div>
  </div>
</div>
