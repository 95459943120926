<app-subscription-menu (clearCart)="clearCart()" (proceedCheckout)="proceedCheckout()">
  <span class="sub-title">Lista de Produtos</span>
</app-subscription-menu>

  <div class="container-fluid px-5" *ngIf="cart.cartItems.length > 0">
      <br><br>
    <div class="row m-0 p-0 cartHeader">
      <div class="col-5">

            <div class="pageTitle">Produto(s) Selecionado(s)</div>
          </div>
          <div class="col-7 ">
            <div class="row p-0">
              <div class="col-7 row p-0">
                <div class="col-4">Preço Unitário</div>
                <div class="col-4 text-left">Quantidade</div>
                <div class="col-4 text-left">Sub-Total</div>
              </div>
              <div class="col-5 row">
                  <div class="col-6 text-left p-0">Entrega</div>
                  <div class="col-6 text-left p-0">Operações</div>
              </div>
            </div>
          </div>
    </div>

    <hr class="mt-0 headerDivider">
    <div *ngIf="cart.cartItems.length > 0">
      <div class="row itemLine" *ngFor="let cartItem of cart.cartItems">
        <div class="col-5">
          <div class="d-flex flex-nowrap">
            <a routerLink="/product/{{cartItem.prd.id}}">
               <div class="checkoutImage mr-2" >
                  <img src="{{imagesUrl + '/store/'+cartItem.prd.img}}">
                </div>
            </a>
            <div class="mr-2 ml-2">
              <div class="title">
                <span >{{cartItem.prd.name}}</span>
              </div>
              <br>
              <div class="description text-muted">
                <span>{{cartItem.prd.name}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7 rightSec">
          <div class="row">
           <div class="col-7 row bot-line ">
              <div class="col-4 row">
                <div class="mt-auto prices-dark">
                  <div class="text-muted"> &nbsp; </div>
                  <span>{{cartItem.price | currency:"€"}}</span>

                </div>
              </div>
              <div class="col-4 text-left subsQtd row">
                <div class="w-100 text-left mt-auto">
                  <div class="text-muted"> &nbsp; </div>
                  <span>{{cartItem.qtd}}</span> Unidades
                </div>
              </div>
                <div class="col-4">
              <div class="w-100 row h-100">
                <div class="col-6 text-left p-0 mt-auto">
                  <span><b>Entrega:</b></span>
                  <span><b>Produto:</b></span>
                </div>
                <div class="col-6 text-center p-0 mt-auto">
                  <span class="text-success"><b>Gratis</b></span>
                  <span class=""><b>{{cartItem.price * cartItem.qtd | currency:"€"}}</b></span>
                </div>
              </div>
              </div>
           </div>
              <div class="col-5">
                <div class="row">
                  <div class="col-6">
                        <div class="w-100 text-left ">
                          <div>
                              <span class="d-block"><b>Periocidade:</b></span>
                              <span class="d-block">Semanal:</span>
                          </div>
                          <div>
                            <span class="d-block"><b>Próxima entrega:</b></span>
                            <span class="d-block">Quarta, 28 de Fevereiro</span>
                          </div>
                        </div>
                  </div>
                  <div class="col-6 operations row">
                      <div class="pl-3 d-inline-block text-left w-100 mt-auto mx-auto">
                          <button class="mx-auto mt-auto" (click)="removeItem(cartItem)">Apagar</button>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="row p-4 total">
        <div class="col-5">
            <button (click)="clearCart()" class="btn btn-primary clearCart" type="button">Limpar Carrinho</button>
        </div>
        <div class="col-7 row">
          <div class="col-3">

          </div>
          <div class="col-3 text-center">
            <span class="total-text">Total:</span>
          </div>
          <div class="col-3 text-center">
              <span class="text-success total-value prices">{{cart.total | currency:'€'}}</span>
          </div>
          <div class="col-3">
              <button class="btn btn-primary w-100 proceedCheck" (click)="proceedCheckout()" type="button">Proceder para o Checkout</button>
          </div>
        </div>
      </div>


    </div>

  </div>
  <div *ngIf="cart.cartItems.length <= 0" class="w-100 text-center">
    <div class="row m-0" style="height:500px;">
        <div class="mx-auto my-auto">
          <div><h3>{{'empty-subscriptions' | translate}}</h3></div>
          <div class="text-muted">
            <h5 style="font-size:1rem;">{{'add-product-prompt' | translate}}</h5>
          </div>
          <div class="text-muted">
            <button class="btn btn-link" routerLink="/browse">{{'actions.to-store' | translate}}</button>
          </div>
        </div>
    </div>
    <app-recommended-line
      actionLabel="actions.add-subscription"
      (productSelected)="handleRecommendedProductsAction($event)"
    ></app-recommended-line>
  </div>
