import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CanonicalUrlService {
  private linkEl: HTMLLinkElement | null = null;

  constructor(@Inject(DOCUMENT) private doc: Document) { }

  set(relativeUrl?: string): void {
    if (this.linkEl === null) {
      this.linkEl = this.doc.createElement('link');
      this.linkEl.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(this.linkEl);
    }

    if (relativeUrl === undefined) {
      this.linkEl.setAttribute('href', this.doc.location.origin + this.doc.location.pathname);
    } else {
      this.linkEl.setAttribute('href', this.doc.location.origin + relativeUrl);
    }
  }
}
