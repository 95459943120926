export const categoryFiltersMap = {
  'species-dog': '5d640a0cb04ade7d51d327b8',
  'species-cat': '5d640a13b04ade7d51d327b9',
  'productType-food': '5d1397ab141b146d1a5313c0',
  'productType-hygiene': '5d139dcb141b146d1a5313c8',
  'productType-snacks': '5d139b06141b146d1a5313c2',
  'productType-health': '5d139b75141b146d1a5313c3',
  'productType-accessories': '5d139c7b141b146d1a5313c4',
  'productType-toys': '5d139cdc141b146d1a5313c5',
  'productType-home': '5d139d24141b146d1a5313c6',
  'productType-deworming': '5d139da7141b146d1a5313c7',
  productType: '5d13974b141b146d1a5313bf',
  species: '5d6409f4b04ade7d51d327b7',
  'species-rabbit': '5ea1d28c69ec715f06102032',
  'species-other': '5ea1d3ba69ec715f06102035',
  'specialty': '5f4e5c25aa118630d1f3c493',
  'specialty-eyes': '5f4e5c6faa118630d1f3c494',
  'specialty-ears': '5f4e5c98aa118630d1f3c495',
  'specialty-skin-fur': '5f4e5cbfaa118630d1f3c496',
  'specialty-mouth-teeth': '5f4e5cd0aa118630d1f3c497',
  'specialty-bones': '5f4e5ce3aa118630d1f3c498',
  'specialty-heart': '5f4e5cf1aa118630d1f3c499',
  'specialty-liver': '5f4e5d1eaa118630d1f3c49a',
  'specialty-kidney': '5f4e5d45aa118630d1f3c49b',
  'specialty-intestine': '5f4e5d7baa118630d1f3c49c',
  'specialty-blood': '5f4e5d8aaa118630d1f3c49d',
  'specialty-immune-system': '5f4e5d98aa118630d1f3c49e',
  'specialty-geriatrics': '5f4e5da7aa118630d1f3c49f',
  'specialty-behavior-nervous-system': '5f4e5db6aa118630d1f3c4a0',
  'specialty-milk-complements': '5f4e5dc9aa118630d1f3c4a1',
  'specialty-healthy-pets': '5f9fed4962d329799ffdf7e6',
  'specialty-obesity-diabetes': '5fa2d22162d329799ffdf7ef',
  'specialty-sensitive-allergies': '5fa2d25062d329799ffdf7f0',
  'dentalb-promo-product': '591318a047d05948b4b4098f',
  hidden: '60b4cf7b0122fa02ee117a72'
};
