import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, forkJoin, of } from 'rxjs';
import { switchMap, take, tap, map, shareReplay, mergeMap, filter, toArray, catchError } from 'rxjs/operators';
import { Rest } from 'rest-lib';
import slugify from '@sindresorhus/slugify';
import { environment } from 'environments/environment';

import { Product } from 'app/models/product';
import { Recommendation } from 'app/models/recommendation';
import { ClinicService } from '../clinic.service';
import { LanguageService } from '../language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ShippingDestinationService } from '../shipping-destination.service';
import { StockService } from '../stock/stock.service';

import { categoryFiltersMap } from '../../category-filters-map';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends Rest<Product> {
  private productUrl = environment.baseUrl + '/public/product';  // URL to web api
  private productsUrl = environment.baseUrl + '/public/product/list';  // URL to web api
  private catalogueUrl = environment.baseUrl;

  productsCache = [];
  requests = {};
  hiddenFeatureId = categoryFiltersMap['hidden'];

  constructor(
    public http: HttpClient,
    private clinicService: ClinicService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private shippingService: ShippingDestinationService,
    private stockService: StockService,
    private utils: UtilsService
  ) {
    super(http);
  }

  getUrl(): string {
    return this.productUrl;
  }

  getUrls(): string {
    return this.productsUrl;
  }

  getById(id: string): Observable<Product> {
    return from(this.get(id)).pipe(
      map(product => {
        if (!product || this.isHidden(product)) {
          throw new Error('Product not found');
        } else {
          return product;
        }
      })
    );
  }

  getAll(): Promise<Product[]> {
    if (this.productsCache.length <= 0) {
      return this.http.get<Product[]>(this.getUrls()).pipe(
        tap(products => this.productsCache = products)
      ).toPromise();
    } else {
      return of(this.productsCache).toPromise();
    }
  }

  getProductsBySpecie(specie: string): Promise<Product[]> {
    return this.getByUrl('/' + specie);
  }

  getProductDetailRecommended(): Promise<Product[]> {
    return this.getAll().then(res => {
      return res.slice(0, 12);
    });
  }

  getTranslatedDescription(product: Product, lang: string) {
    const description = product.descriptions.find(item => item.language === lang);
    if (description) {
      return description;
    }
    return product.descriptions.find(item => item.language === 'pt');
  }

  getTranslatedFeatures(product: Product, lang: string) {
    if (!product.features) {
      return [];
    }

    const translationGroups = product.features
      .map(feature => feature.productFeaturesTranslations)
      .filter(translations => translations !== undefined);

    return translationGroups
      .reduce((flattened, translations) => flattened.concat(...translations), [])
      .filter(translation => translation.language === lang)
      .map(translation => translation.value);
  }

  getProductsInRange(id: string, countryCode: string): Observable<Product[]> {
    const url = `${this.productUrl}/${id}/range/${countryCode}`;
    return this.http.get<string[]>(url).pipe(
      switchMap(productIds => {
        const productRequests = productIds.map(id => {
          return this.getById(id).pipe(
            catchError(() => of(null))
          );
        });

        return forkJoin(productRequests).pipe(
          map(products => products.filter(p => p !== null))
        );
      })
    );
  }

  getByCountry(countryCode: string): Observable<Product[]> {
    const url = `${this.catalogueUrl}/public/product/byCountry/${countryCode}`;
    if (this.requests[url] === undefined) {
      this.requests[url] = this.http.get<Product[]>(url).pipe(
        map(productList => productList.filter(product => !this.isHidden(product))),
        shareReplay(1)
      );
    }
    return this.requests[url];
  }

  getByCountryInStock(countryCode?: string): Observable<Product[]> {
    const countryRequest = countryCode !== undefined
      ? of(countryCode)
      : this.shippingService.getDestination().pipe(map(destination => destination.code));
    const productsRequest = countryRequest.pipe(
      take(1),
      // get all products
      switchMap(country => this.getByCountry(country)),
      // emit each product
      switchMap(productList => from(productList)),
      // filter out of stock products
      mergeMap(product => this.stockService.isStockAvailable(product.id).pipe(
        map(inStock => inStock ? product : null)
      )),
      filter(product => product !== null),
      // recollect products in array
      toArray()
    );
    return productsRequest;
  }

  getSortedByPrice(countryCode: string): Observable<string[]> {
    const url = `${this.catalogueUrl}/public/product/orderedByPrice/${countryCode}`;
    return this.http.get<string[]>(url);
  }

  getRecommendation(productId: string): Observable<Recommendation> {
    return this.clinicService.getActiveClinic().pipe(
      take(1),
      switchMap(clinic => {
        if (clinic !== null) {
          const url = `${this.catalogueUrl}/public/product/${clinic}/productId/${productId}`;
          return this.http.get<Recommendation>(url);
        } else {
          return of({ recommended: true, text: '' });
        }
      })
    );
  }

  private getRecommendedProductList(clinicId: string, countryCode: string): Observable<string[]> {
    const url = `${this.catalogueUrl}/public/product/${clinicId}/recommended/${countryCode}`;
    if (this.requests[url] === undefined) {
      this.requests[url] = this.http.get<string[]>(url).pipe(
        shareReplay(1)
      );
    }
    return this.requests[url];
  }

  isRecommended(productId: string): Observable<boolean> {
    return this.clinicService.getActiveClinic().pipe(
      take(1),
      mergeMap(clinic => {
        if (!clinic) {
          return of([]);
        }
        return this.getRecommendedProductList(clinic, 'pt');
      }),
      map(list => list.indexOf(productId) > 0)
    );
  }

  getTitle(product: Product): Observable<string> {
    return this.languageService.currentLanguage.pipe(
      map(language => this.getTranslatedDescription(product, language)),
      map(description => {
        if (product.sellingQuantity && product.sellingQuantity > 1) {
          const unitPackString = this.translateService.instant('unit-pack', { units: product.sellingQuantity });
          return description.name + ' | ' + unitPackString;
        }
        return description.name;
      })
    )
  }

  titleSlug(product: Product): Observable<string> {
    return this.getTitle(product).pipe(
      map(title => slugify(title))
    );
  }

  makeUrl(product: Product): Observable<string> {
    return this.titleSlug(product).pipe(
      map(slug => `/product/${product.id}/${slug}`)
    );
  }

  private isHidden(product: Product) {
    return product.features.some(f => f.featureId === this.hiddenFeatureId);
  }
}
