import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DarkenOverlayService {
  state = new BehaviorSubject<boolean>(false);
  clicks = new Subject<any>();

  constructor() { }

  broadcastClick() {
    this.clicks.next();
  }

  getState(): Observable<boolean> {
    return this.state.asObservable();
  }

  enable(): void {
    this.state.next(true);
  }

  disable(): void {
    this.state.next(false);
  }

  userClicks(): Observable<any> {
    return this.clicks.asObservable();
  }
}
