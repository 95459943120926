import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  subject = new Subject<any>();
  timeout = 5000;

  constructor(private translateService: TranslateService) { }

  sendMessage(
    translationKey: string,
    options: {
      autoDismiss?: boolean,
      type?: string,
      translationParams?: any
    } = {}
  ) {
    const type = options.type !== undefined ? options.type : 'warning';
    const autoDismiss = options.autoDismiss !== undefined ? options.autoDismiss : true;
    const translationParams = options.translationParams;
    const message = this.translateService.instant(translationKey, translationParams);
    const date = new Date();
    this.subject.next({ message, type, autoDismiss, timeout: this.timeout, date });
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

}
