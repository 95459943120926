import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { UserNotificationService } from '../../services/language/user-notification.service';
import { AuthService } from '@auth0/auth0-angular';
import { SubscriptionService } from '../../services/subscription/subscription.service';
import { Subcart } from '../../models/subcart';
import { SubcartItem } from '../../models/subcart-item';
import { ProductsService } from 'app/services/product/products.service';
import { Product } from 'app/models/product';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  providers: [ProductsService]
})
export class SubscriptionComponent implements OnInit {

  cart: Subcart;
  imagesUrl = environment.baseUrlServices;
  constructor(private subsService: SubscriptionService, private notificationService: UserNotificationService, private auth: AuthService
    , private router: Router) {
    this.cart = this.subsService.subcart;
  }

  ngOnInit() {
    this.subsService.getCart().subscribe(res => {
      this.cart = res;
      console.log('cartUpdated with: ', this.cart || 'is null');
    });
  }

  clearCart() {
    this.subsService.clearCart();
    this.notificationService.sendMessage('<u>Subscrições eliminadas com sucesso!</u>' , { type: 'success' });
  }

  removeItem(item: SubcartItem) {
    this.subsService.removeCartItem(item);
    this.notificationService.sendMessage(item.prd.name + ' | <u>removido das subscrições com sucesso!</u>' , { type: 'success' });
  }

  proceedCheckout() {
    this.router.navigateByUrl('/cart/checkout');
    /*  if (this.auth.authenticated() === true && this.auth.userProfile) {
     } else { this.notificationService.sendMessage('Necessita Iniciar Sessão para fazer o checkout...'); } */
  }


  handleQuant(item: SubcartItem, type: string) {
    switch (type) {
      case 'plus': item.qtd++; this.cart.total = this.cart.total + item.price; break;
      case 'minus':
        if (item.qtd > 1) {
          item.qtd--; this.cart.total = this.cart.total - item.price;
        } else {
          this.notificationService.sendMessage(
            '<u>Para Remover um Item das suas Subscrições use o Botão de Apagar da área de Operações.</u>'
          );
        } break;
      default: break;
    } this.subsService.setCart(this.cart);
  }

  // TODO: implement
  handleRecommendedProductsAction(event): void {
    return;
  }

}
