import { Component, OnInit } from '@angular/core';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';

import { Slide } from './slide';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {
  imgRoot = 'assets/images/';
  slides: Slide[] = [
    {
      id: 'omnicondro-slide',
      img: 'omnicondro-slide.png',
      navText: 'Omnicondro',
      alt: 'Banner Omnicondro',
      url: '/browse',
      queryParams: { q: 'omnicondro' }
    },
    {
      id: 'my-vet-slide',
      img: 'myvet-slide.png',
      navText: 'genialvet',
      alt: 'Banner MyVet',
      url: '/profile/clinic'
    },
    {
      id: 'quantity-promo-slide',
      img: 'quantity-promo-slide.jpg',
      navText: 'Promoção Quantidade',
      alt: 'Banner promoção quantidade',
      url: '/browse',
      queryParams: { s: 'species-dog' }
    }
  ];

  swiperConfig: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    centeredSlides: true,
    loop: true,
    simulateTouch: true,
    speed: 1000,
    freeModeMomentum: true,
    followFinger: false,
    observer: true,
    preloadImages: true,
    longSwipesMs: 500,
    updateOnImagesReady: true,
    freeModeMinimumVelocity: 0.1,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.rightRs',
      prevEl: '.leftRs',
    },
    pagination: {
      el: '.swipages',
      clickable: true,
      bulletClass: 'Texttab',
      bulletActiveClass: 'hovered-TextTab',
      totalClass: 'swiperClass',
      renderBullet: (index, className) => {
        return `<li class="nav-item my-auto nav-link slideNav ${className}">`
          + this.slides[index].navText
          + '</li>';
      }
    }
  };

  index = 0;

  constructor() {
    SwiperCore.use([Pagination, Navigation]);
  }

  ngOnInit() { }
}

