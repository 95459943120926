import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/services/language/language.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { PromotionMessageService } from '../../services/promotion-message.service';

@Component({
  selector: 'app-promotion-notice',
  templateUrl: './promotion-notice.component.html',
  styleUrls: ['./promotion-notice.component.scss']
})
export class PromotionNoticeComponent implements OnInit {
  promotionalMessage = null;
  text = null;
  targetUrl = '';

  constructor(
    private promotionMessageService: PromotionMessageService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.promotionMessageService.getActive().pipe(
      tap(pm => this.promotionalMessage = pm),
      tap(pm => this.text = this.languageService.currentLanguage.pipe(
        map(lang => lang === 'gb' ? 'en' : lang),
        map(lang => pm.messages[lang])
      )),
      tap(pm => this.targetUrl = pm.targetLink.join(','))
    ).subscribe();
  }

}
