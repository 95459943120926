import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/pt';
import { Client } from '../../../models/client';
import { ClientService } from '../../../services/user/client.service';
import { Router } from '@angular/router';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'pt');

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  client: Client;
  profile = null;

  constructor(
    private auth: AuthService,
    private clientService: ClientService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    }, error => {
      console.error('UserComponent: Failed to get client.', error);
    });
    this.auth.user$.subscribe(profile => {
      this.profile = profile;
    });
  }

  handleLoginClick() {
    const url = this.router.url;
    this.auth.loginWithRedirect();
  }

  handleLogout() {
    this.auth.logout({ returnTo: this.document.location.origin });
  }
}
