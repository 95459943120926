import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { from, merge, Observable, Subscription, of } from 'rxjs';
import { map, scan, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { UserNotificationService } from 'app/services/language/user-notification.service';
import { ProductsService } from 'app/services/product/products.service';
import { CartService } from 'app/services/cart/cart.service';
import { PriceService } from 'app/services/price/price.service';

import { Product } from 'app/models/product';
import { Price } from 'app/models/price';
import { RecommendedProductsService } from 'app/services/recommended-products.service';
import { AnimalService } from 'app/services/animal/animal.service';
import { FeatureService } from 'app/services/feature.service';
import { StockService } from 'app/services/stock/stock.service';
import SwiperCore, { Autoplay, Pagination, SwiperOptions } from 'swiper';

@Component({
  selector: 'app-recommended-line',
  templateUrl: './recommended-line.component.html',
  styleUrls: ['./recommended-line.component.scss']
})
export class RecommendedLineComponent implements OnInit, OnChanges, OnDestroy {
  @Input() heading = '';
  @Input() actionLabel = 'actions.add-cart';
  @Input() species: string;
  @Input() fullscreen = false;

  index = 0;
  swiperConfig: SwiperOptions = {
    direction: 'horizontal',
    keyboard: true,
    centeredSlides: false,
    simulateTouch: true,
    speed: 1500,
    followFinger: true,
    observer: true,
    preloadImages: true,
    longSwipesMs: 500,
    updateOnImagesReady: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    }
  };
  products: Product[] = [];
  prices: { [id: string]: Price } = {};
  stocks: { [id: string]: boolean } = {};
  imagesUrl = environment.baseUrlServices;
  pricesSub: Subscription;
  productTitlesMap = null;

  @Output() productSelected = new EventEmitter<{ product: Product, price: Price }>();

  constructor(
    private prodService: ProductsService,
    private cartService: CartService,
    private notificationService: UserNotificationService,
    private priceService: PriceService,
    private recommendedProductsService: RecommendedProductsService,
    private petService: AnimalService,
    private featureService: FeatureService,
    private stockService: StockService,
    private productService: ProductsService
  ) {
    SwiperCore.use([Pagination, Autoplay]);
  }

  ngOnInit() {
    this.setSwiperBreakpoints();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.species) {
      const species = changes.species.currentValue;
      this.recommendedProductsService.getRandomPromoted(species).subscribe(products => {
        this.setupProducts(products);
      });
    }
  }

  setupProducts(products: Product[]): void {
    this.pricesSub = this.priceService.getActivePrices(products.map(p => p.id))
      .subscribe(prices => this.prices = prices);
    this.stockService.getStockAvailableMap(products.map(p => p.id))
      .subscribe(stocks => this.stocks = stocks);
    this.products = products.slice(0, 12);
    this.productTitlesMap = new Map(products
      .map(product => [product.id, this.productService.getTitle(product)]));
  }

  addToCart(product: Product) {
    this.cartService.addToCart(product, 1).subscribe();
  }

  assignPrice(prices: any, id: string, price: number) {
    const newPrices = Object.assign({}, prices);
    newPrices[id] = price;
    return newPrices;
  }

  getPriceMap(productIds: string[]): Observable<{ [id: string]: number }> {
    const makeRequest = (id: string) => this.priceService.getActivePrice(id)
      .pipe(map(price => ({ [id]: price.price })));
    return merge(...productIds.map(id => makeRequest(id)))
      .pipe(scan((m, p) => ({ ...m, ...p })));
  }

  handleProductActionClick(event: Product): void {
    const payload = { product: event, price: this.prices[event.id] };
    this.productSelected.emit(payload);
  }

  setSwiperBreakpoints() {
    if (this.fullscreen) {
      this.swiperConfig.breakpoints = {
        600: {
          slidesPerView: 2
        },
        900: {
          slidesPerView: 4
        }
      };
    } else {
      this.swiperConfig.breakpoints = {
        600: {
          slidesPerView: 2
        },
        900: {
          slidesPerView: 4
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.pricesSub) {
      this.pricesSub.unsubscribe();
    }
  }
}
