<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-6 col-xl-3 text-center adItem">
      <a routerLink="/browse" [queryParams]="{ manufacturer: '5cdadd8444b0212725e71a36' }" class="ad-link">
        <img src="/assets/images/royal-canin-banner.png" class="topAds" width="400" height="192">
      </a>
    </div>
    <div class="col-12 col-sm-6 col-xl-3 text-center adItem">
      <a routerLink="/browse" [queryParams]="{ manufacturer: '5def6cda44b0212ac2f99a23' }" class="ad-link">
        <img src="/assets/images/advance-banner.png" class="topAds" width="400" height="192">
      </a>
    </div>
    <div class="col-12 col-sm-6 col-xl-3 text-center adItem">
      <a routerLink="/clinic-registration" class="ad-link">
        <img src="/assets/images/clinic-registration-banner.png" class="topAds" width="400" height="192">
      </a>
    </div>
    <div class="col-12 col-sm-6 col-xl-3 text-center adItem">
      <a routerLink="/profile/clinic" class="ad-link">
        <img src="/assets/images/user-guide-banner.png" class="topAds" width="400" height="192">
      </a>
    </div>
  </div>
</div>
