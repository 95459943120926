import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { toArray } from 'rxjs/operators';

import { ProductsService } from 'app/services/product/products.service';
import { RecommendedProductsService } from 'app/services/recommended-products.service';
import { AnimalService } from 'app/services/animal/animal.service';

import { Product } from '../../models/product';
import { Pet } from 'app/models/pet';
import { ShippingDestinationService } from 'app/services/shipping-destination.service';
import { LoadingService } from 'app/services/loading.service';

import { categoryFiltersMap } from 'app/category-filters-map';
import { UtilsService } from 'app/services/utils.service';
import { StockService } from 'app/services/stock/stock.service';
import { BlogService } from '../../services/blog.service';

const productTypes = ['food', 'hygiene', 'health'];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  products: Product[] = [];
  advised: Product[] = [];
  grid: Product[] = [];
  recomended: Product[] = [];
  activePet: Pet = null;
  currentCountry = 'pt';
  destinationSub: Subscription;
  isLoading = true;

  constructor(
    private productService: ProductsService,
    private recommendedProducts: RecommendedProductsService,
    private petService: AnimalService,
    private shippingService: ShippingDestinationService,
    private loadingService: LoadingService,
    private utils: UtilsService,
    private stockService: StockService
  ) { }

  ngOnInit() {
    this.loadingService.enable();

    this.destinationSub = this.shippingService.getDestination().subscribe(destination => {
      this.isLoading = true;
      this.loadingService.enable();

      this.productService.getByCountry(destination.code).subscribe(products => {
        this.advised = products.slice(2, 6);

        this.buildRecommendedProducts(products);

        this.petService.getSelection().subscribe(pet => {
          this.activePet = pet;
          const species = pet && pet.species && this.isPromotedSpecies(pet.species) ? pet.species : undefined;

          this.recommendedProducts.forGrid(products, productTypes, species)
            .pipe(toArray())
            .subscribe(categoryList => {
              const highlights = categoryList.map(l => l.list[0]);
              const other = categoryList.reduce((result, l) => result.concat(l.list[1], l.list[2]), []);
              this.grid = highlights;
              this.recomended = other;
            });
        });
      });

      this.loadingService.disable();
      this.isLoading = false;
    });
  }

  buildRecommendedProducts(allProducts: Product[]) {
    const productIds = allProducts.filter(p => p !== undefined && p.id !== undefined).map(p => p.id);
    this.utils.partitionAsync(productIds, (p) => this.stockService.isStockAvailable(p))
      .subscribe(([available, _]) => {
        const picks = this.utils.takeRandom(available, 6);
        this.products = picks.map(productId => allProducts.find(p => p.id === productId));
      });
  }

  isPromotedSpecies(speciesId: string): boolean {
    return speciesId === categoryFiltersMap['species-dog']
      || speciesId === categoryFiltersMap['species-cat'];
  }

  ngOnDestroy() {
    this.destinationSub.unsubscribe();
  }
}
