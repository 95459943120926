<div class="row">
  <div class="col-12 p-0" style="margin-top: 0.8em;">
    <form (ngSubmit)="search()">
      <div class="input-group" id="searchBar">
        <!-- species selector hidden -->
        <!--
        <div class="input-group-btn">
          <button
            type="button"
            id="search-departement"
            class="btn"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{selectedSpecies
              ? (selectedSpecies.featureLang | objTranslate | async).name
              : 'all' | translate
            }} &nbsp;<i class="fa fa-angle-down" aria-hidden="true" style="color:black"></i>
          </button>
          <div class="dropdown-menu">
            <a (click)="onSpeciesSelect(null)" class="dropdown-item species-item">
              {{'all' | translate}}
            </a>
            <a
              *ngFor="let animal of species"
              class="dropdown-item species-item"
              (click)="onSpeciesSelect(animal)"
            >
              {{(animal.featureLang | objTranslate | async).name}}
            </a>
          </div>
        </div>
        <span style="border-left: 1px solid black; height: 1.5em; margin-top: auto; margin-bottom: auto;"></span>
        -->

        <input
          type="text"
          class="form-control"
          aria-label="Text input with dropdown button"
          placeholder="Procurar por animal, produtos..."
          name="query"
          [formControl]="searchControl"
        >
        <div class="input-group-append magnifier">
          <!-- removi a class btn-secondary-->
          <button
            type="submit"
            aria-haspopup="true"
            aria-expanded="false"
            class="search-button"
          >
            <img
              id="magnifier"
              src="/assets/images/icons/magnifier.png"
              alt="Search"
              width="20"
              height="20"
            >
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="search-preview" *ngIf="previewVisible">
    <app-search-preview [query]="searchPreviewQuery"></app-search-preview>
  </div>
</div>
