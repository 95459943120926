import { Component, OnInit, Input } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

import { UserNotificationService } from 'app/services/language/user-notification.service';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss']
})
export class UserNotificationComponent implements OnInit {
  message = 'ERROR: NO MESSAGE!';
  notifications = [];

  constructor(
    private notificationService: UserNotificationService,
    private _scrollToService: ScrollToService
  ) { }

  ngOnInit() {
    this.notificationService.getMessage().subscribe(alert => {
      this.notifications = this.notifications.concat(alert);

      if (alert.autoDismiss) {
        setTimeout(() => {
          this.dismiss(alert);
        }, alert.timeout);
      }
    });
  }

  public triggerScrollToOffsetOnly(offset = 0) {
    const config: ScrollToConfigOptions = {
      offset: -offset,
      target: 'notifyUser',
      duration: 500,
      easing: 'easeOutElastic',
    };

    this._scrollToService.scrollTo(config);
  }

  dismiss(alert) {
    this.notifications = this.notifications.filter(a => a !== alert);
  }

  getScrollOffset(pixelRatio: number) {
    if (pixelRatio === 1) {
      return 0;
    } else if (pixelRatio === 1.25) {
      return 31;
    }
  }
}
