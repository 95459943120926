import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BaseRest } from 'rest-lib';
import { HttpClient } from '@angular/common/http';
import { Stock } from 'app/models/stock';
import { merge, Observable } from 'rxjs';
import { map, scan, shareReplay } from 'rxjs/operators';
import { StockState } from 'app/models/enums/stock-state.enum';

@Injectable({ providedIn: 'root' })
export class StockService extends BaseRest<any> {
  private userUrl = environment.shopUrl + '/stock'
  stock: Stock;
  allStockCache: { [url: string]: Observable<Stock[]> } = {};

  constructor(http: HttpClient) {
    super(http);
  }

  getUrl(): string {
    return this.userUrl;
  }

  getStock(id: string, countryCode = 'pt'): Observable<Stock> {
    const url = `${this.userUrl}/list/${countryCode}`;
    if (!this.allStockCache[url]) {
      this.allStockCache[url] = this.http.get<Stock[]>(url).pipe(
        shareReplay(1)
      );
    }
    return this.allStockCache[url].pipe(
      map(stocks => stocks.find(s => s.productId === id))
    );
  }

  isStockAvailable(productId: string): Observable<boolean> {
    return this.getStock(productId).pipe(
      map(stock => stock ? StockState[stock.state] : StockState.NoInfo),
      map(state => state === StockState.LowStock || state === StockState.Now)
    );
  }

  getStockAvailableMap(productIds: string[]): Observable<{ [id: string]: boolean }> {
    const makeRequest = (id: string) => this.getStock(id).pipe(
      map(stock => stock ? StockState[stock.state] : StockState.NoInfo),
      map(state => ({ [id]: state === StockState.LowStock || state === StockState.Now })));
    return merge(...productIds.map(id => makeRequest(id)))
      .pipe(scan((m, p) => ({ ...m, ...p }), {}));
  }
}
