import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

import { Language } from '../../models/language';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  currentLanguage = new BehaviorSubject<string>(this.translateService.getDefaultLang());
  languages = ['pt', 'es', 'gb'];
  private url = environment.baseUrl + '/language';
  private language: Language;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    if (this.language === null) {
      this.findLanguage();
    }
  }

  getLanguage() {
    return this.language;
  }

  private findLanguage(): Promise<Language> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => {
        const p = response as Language;
        this.language = p;
        return p;
      }, reason => {
        console.log(reason);
        console.log('fail');
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
