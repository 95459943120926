<h4 style="color:#008675 !important" class="p-3 font-weight-bold ml-0">
  <span *ngIf="!activePet">{{'products-for-pet.none' | translate}}</span>
  <span *ngIf="activePet">{{'products-for-pet.' + activePet.sex | translate}} {{activePet.name}}</span>
</h4>

<div class="row container-fluid clearfix" *ngIf="template === 0">
  <div class="col-12 col-xl-auto p-0 grid-division">
    <div class="signs h-100 text-center">
      <a routerLink="/product/{{dentalbPromoId}}">
        <img class="promotional-banner" src="/assets/images/dentalb-poster.jpg" alt="" draggable="false">
      </a>
    </div>
  </div>

  <div class="col-12 col-xl p-0 grid-division mid-div d-flex flex-column">
    <app-pet-product-suggestion-row
      category="food"
      [products]="{ primary: prods[0], extraOne: lastProds[0], extraTwo: lastProds[1] }"
    ></app-pet-product-suggestion-row>
    <app-pet-product-suggestion-row
      category="hygiene"
      [products]="{ primary: prods[1], extraOne: lastProds[2], extraTwo: lastProds[3] }"
    ></app-pet-product-suggestion-row>
    <app-pet-product-suggestion-row
      category="health"
      [products]="{ primary: prods[2], extraOne: lastProds[4], extraTwo: lastProds[5] }"
    ></app-pet-product-suggestion-row>
  </div>
</div>

<div class="row container-fluid clearfix" *ngIf="template === 1">
  <div class="col-4 p-0 grid-division">
    <div class="signs h-50">
      <img class="w-100 h-100 img-thumbnail" src="/assets/images/Paws_preview.jpg" alt="" draggable="false">
    </div>
    <div class="signs h-50">
      <img class="w-100 h-100 img-thumbnail" src="/assets/images/Preview Image.jpg" alt="" draggable="false">
    </div>
  </div>

  <div class="col-5 p-0 grid-division mid-div">
    <div
      *ngFor="let produto of prods; let i = index"
      class="b-line middle-card row firstBlock"
      [ngClass]="paintBlocks(i)"
    >
      <a class="w-100 h-100" routerLink="{{produto | productUrl | async}}">
        <div class="hvr-div w-100 row">
          <div class="col-8">
            <div class="w-100 h-100">
              <div class="text-left p-4 h-100">
                <h5 class="text-left pName">{{marcas[i]}}</h5>
                <h5>
                  <span class="text-muted pNameDesc show-more-text">{{produto?.name}}</span>
                  <br>
                  <span class="pPrice">{{(produto?.price || 0) | cost:'EUR'}}</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="prodImage">
              <img *ngIf="produto" [appStoreImage]="produto.img" size="small" alt="" class="w-100">
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div class="col-3 p-0 grid-division row ">
    <div class="thirdColContainer w-100 mx-auto my-auto">
      <div *ngFor="let produto of lastProds; let i = index" class="container-fluid thirdCol">
        <a
          class="w-100 h-100"
          routerLink="/product/{{produto?.id}}"
        >
          <div class="p-1 row r-col-item" [class.b-line]="i !== lastProds.length - 1">
            <div class="col-9">
              <div class="p-2 show-more-text h-100">
                <span class="sectionRight_name"><b>{{produto?.name}}</b></span>
                <br>
                <span class="text-success sectionRight_price">{{(produto?.price || 0) | cost:'EUR'}}</span>
              </div>
            </div>
            <div class="col-3">
              <img *ngIf="produto" [appStoreImage]="produto?.img" size="small" class="picture">
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
