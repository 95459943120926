<a
  [routerLink]="['/info', targetUrl]"
  class="d-block p-2 text-center promotion-notice"
  [ngStyle]="{ 'background-color': promotionalMessage !== null ? promotionalMessage.color : 'rgb(255, 227, 127)' }"
>
  <p
    class="mb-0 font-weight-light message"
    [ngStyle]="{ 'color': promotionalMessage !== null ? promotionalMessage.textColor : 'red' }">
    {{text | async}}
  </p>
</a>
