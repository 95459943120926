<div class="swiper-container">
  <swiper *ngIf="slides" [config]="swiperConfig" [(index)]="index">
    <ng-template *ngFor="let slide of slides" swiperSlide>
      <a
        [routerLink]="slide.url !== undefined ? slide.url : ''"
        [queryParams]="slide.queryParams !== undefined ? slide.queryParams : {}"
        class="slide-link-wrapper"
      >
        <img class="slider-item" [src]="imgRoot + slide.img" [alt]="slide.alt">
      </a>
    </ng-template>
  </swiper>
</div>

<div class="row header">
  <div class="container-fluid w-100">
    <nav
      class="navbar nav-pills nav-justified swipages flex-column flex-md-row align-items-stretch"
    ></nav>
    <hr class="slogan-line">
    <div class="col-12 mx-auto slogan" style="margin-top: -1.2em;">
      <h3>Todos os produtos do seu veterinário, online e ao melhor preço</h3>
    </div>
  </div>
</div>
